import { ApiClient, ApiRequest } from '@/common/types'

class RoomApis {
  public getRoomDetail: ApiRequest<RentAPerfect.Room, { roomId: string }>
  public requestAppointment: ApiRequest<
    RentAPerfect.Room,
    {
      roomId: string
      firstDate: string
      firstTime: string
      secondDate: string
      secondTime: string
      thirdDate: string
      thirdTime: string
    }
  >
  public cancelAppointments: ApiRequest<void, { roomId: string }>

  constructor({ createRequest }: ApiClient) {
    this.getRoomDetail = createRequest({
      method: 'get',
      url: ({ roomId }) => `/rooms/${roomId}`,
      getResponseData: response => ({
        ...response.data.room,
        otherRooms: response.data.otherRooms,
      }),
    })

    this.requestAppointment = createRequest({
      method: 'post',
      url: ({ roomId }) => `/rooms/${roomId}/appointments`,
      payload: ({ roomId, ...props }) => props,
      getResponseData: response => response.data.updatedRoom,
    })

    this.cancelAppointments = createRequest({
      method: 'delete',
      url: ({ roomId }) => `/rooms/${roomId}/appointments`,
    })
  }
}

export default RoomApis

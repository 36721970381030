import React from 'react'

import { IconProps } from './types'

const ArrowDownIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 12'
      height={size}
      {...props}
    >
      <g>
        <path fill='none' d='M0 0H12V12H0z' transform='rotate(180 6 6)' />
        <g>
          <g>
            <path
              fill='currentColor'
              d='M6.744 5.439L1.493.189a.647.647 0 0 0-.913 0L.193.575a.646.646 0 0 0 0 .913L4.6 5.9.189 10.312a.647.647 0 0 0 0 .913l.387.387a.647.647 0 0 0 .913 0l5.255-5.256a.651.651 0 0 0 0-.916z'
              transform='rotate(180 6 6) rotate(-90 4.766 4.666)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ArrowDownIcon

import { HOME_PATH } from '@/common/paths'

export const SIGN_IN_PATH = '/sign-in'

export const SOCIAL_SIGN_IN_PATH = '/auth/social/:token'

export const SIGN_IN_REDIRECT_PATH = HOME_PATH

export const SIGN_UP_PATH = '/sign-up'

export const VERIFY_EMAIL_PATH = '/verify-email'

export const FORGOT_PASSWORD_PATH = '/forgot-password'

export const RESET_PASSWORD_PATH = '/reset-password'

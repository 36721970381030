export const ME_PATH = '/me'

export const ME_PERSONAL_INFORMATION_PATH = `${ME_PATH}/personal-information`
export const ME_PERSONAL_INFORMATION_EDIT_PATH = `${ME_PERSONAL_INFORMATION_PATH}/edit`

export const ME_RENTAL_HISTORY_PATH = `${ME_PATH}/rental-history`
export const ME_RENTAL_HISTORY_PAYMENTS_PATH = `${ME_RENTAL_HISTORY_PATH}/:rentalId/payments`

export const ME_SAVED_LISTINGS_PATH = `${ME_PATH}/saved-listings`

export const ME_ADVANCED_SEARCH_HISTORY_PATH = `${ME_PATH}/advanced-search-history`

export const ME_BROKER_PROFILE_PATH = `${ME_PATH}/broker-profile`
export const ME_BROKER_PROFILE_EDIT_PATH = `${ME_BROKER_PROFILE_PATH}/edit`

export const ME_BROKER_LISTINGS_PATH = `${ME_PATH}/broker-listings`
export const ME_BROKER_LISTING_DEALS_PATH = `${ME_BROKER_LISTINGS_PATH}/:roomId/deals`

export const ME_OWNER_PROFILE_PATH = `${ME_PATH}/owner-profile`
export const ME_OWNER_PROFILE_EDIT_PATH = `${ME_OWNER_PROFILE_PATH}/edit`

export const ME_OWNER_LISTINGS_PATH = `${ME_PATH}/owner-listings`
export const ME_OWNER_LISTING_CREATE_PATH = `${ME_OWNER_LISTINGS_PATH}/create`
export const ME_OWNER_LISTING_EDIT_PATH = `${ME_OWNER_LISTINGS_PATH}/:roomId/edit`
export const ME_OWNER_LISTING_RENTAL_HISTORY_PATH = `${ME_OWNER_LISTINGS_PATH}/:roomId/rental-history`
export const ME_OWNER_LISTING_RENTAL_PAYMENTS_PATH = `${ME_OWNER_LISTING_RENTAL_HISTORY_PATH}/:rentalId/payments`
export const ME_OWNER_LISTING_BORKER_LIST_PATH = `${ME_OWNER_LISTINGS_PATH}/:roomId/brokers`

import styled, { css } from 'styled-components'

import { media } from '@/common/utils/viewport'

interface ImageSizeProps {
  $width?: string | number
  $height?: string | number
  $tabletWidth?: string | number
  $tabletHeight?: string | number
  $mobileWidth?: string | number
  $mobileHeight?: string | number
}

interface StyledImageProps extends ImageSizeProps {
  $cover: boolean
}

const applyImageSize = ({
  $width,
  $height,
  $tabletWidth,
  $tabletHeight,
  $mobileWidth,
  $mobileHeight,
}: ImageSizeProps) => {
  const getCss = (_width?: string | number, _height?: string | number) => {
    const width = typeof _width === 'number' ? `${_width}px` : _width
    const height = typeof _height === 'number' ? `${_height}px` : _height

    return css`
      ${typeof width !== 'undefined' &&
      css`
        width: ${width};
      `}
      ${typeof height !== 'undefined' &&
      css`
        height: ${height};
      `}
    `
  }

  return css`
    ${getCss($width, $height)}

    ${media.down('lg')`
      ${getCss($tabletWidth, $tabletHeight)}
    `}

    ${media.down('sm')`
      ${getCss($mobileWidth, $mobileHeight)}
    `}
  `
}

export const StyledImage = styled.img<StyledImageProps>`
  ${applyImageSize}
  object-fit: ${({ $cover }) => ($cover ? 'cover' : 'contain')};
`

export const NoImage = styled.div`
  ${applyImageSize}
  background-color: #f2f2f2;
`

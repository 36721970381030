import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'qs'

import { ApiClient, ApiRequest, ApiCookie } from '@/common/types'
import { isSSR } from '@/common/utils/server'

import ArticleApis from './article'
import AuthApis from './auth'
import BrokerApis from './broker'
import CondoApis from './condo'
import OwnerApis from './owner'
import ReviewApis from './review'
import RoomApis from './room'
import SearchApis from './search'
import UserApis from './user'

const UNAUTHORIZED_CODE = 401

class Apis {
  private isRefreshingAccessToken = false
  private retryRequestTasks: ((error: AxiosError | null) => void)[] = []
  private errorStateListener: ((error: AxiosError | null) => void)[] = []

  private client: ApiClient
  public auth: AuthApis
  public article: ArticleApis
  public search: SearchApis
  public condo: CondoApis
  public room: RoomApis
  public user: UserApis
  public broker: BrokerApis
  public owner: OwnerApis
  public review: ReviewApis

  constructor(
    private axios: AxiosInstance,
    private cookie: ApiCookie,
    private defaultRequestConfig: Partial<AxiosRequestConfig>
  ) {
    this.setupClient()
    this.setupInterceptors()

    this.auth = new AuthApis(this.client, this.cookie)
    this.article = new ArticleApis(this.client)
    this.search = new SearchApis(this.client)
    this.condo = new CondoApis(this.client)
    this.room = new RoomApis(this.client)
    this.user = new UserApis(this.client)
    this.broker = new BrokerApis(this.client)
    this.owner = new OwnerApis(this.client)
    this.review = new ReviewApis(this.client)
  }

  setupClient() {
    this.client = {
      createRequest: <R, P>({
        payload: _payload,
        isFormData = false,
        callback,
        onRejected,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getResponseData = (response, _props) => response?.data,
        ...config
      }) =>
        (async _props => {
          const { _ignoreError, ...props } = _props ?? {}
          const ref = isSSR() ? undefined : sessionStorage.getItem('ref')
          const payload = isFormData
            ? _payload?.(props)
            : {
                ..._payload?.(props),
                ...(ref ? { brokerRef: Number(ref) } : {}),
              }

          if (isFormData && ref) {
            payload.append('brokerRef', ref)
          }

          const response = await this.axios({
            ...this.defaultRequestConfig,
            ...config,
            headers: {
              ...this.defaultRequestConfig.headers,
              ...config.headers,
              ...(isFormData ? { 'Content-Type': 'multipart/form-data' } : {}),
            },
            url: config.url?.(props),
            ...(config.method === 'get'
              ? { params: { _ignoreError, ...payload } }
              : { params: { _ignoreError }, data: payload }),
          }).catch(error => {
            if (!onRejected) throw error
            onRejected(error)
          })

          callback?.(response, props)
          return getResponseData(response, props)
        }) as ApiRequest<R, P>,
    }
  }

  setupInterceptors() {
    this.axios.interceptors.request.use(_config => {
      const config = {
        ..._config,
        paramsSerializer: params =>
          qs.stringify(params, { arrayFormat: 'brackets', encode: false }),
      }

      if (this.auth.accessToken) {
        return this.configWithAuthorization(config, this.auth.accessToken)
      }

      return config
    })

    this.axios.interceptors.response.use(
      response => {
        this.notifyErrorStateListener(null)
        return response
      },
      (error: AxiosError) => {
        if (!this.isAccessTokenExpired(error)) {
          if (
            error.response?.status !== UNAUTHORIZED_CODE &&
            !error.config.params?._ignoreError
          ) {
            this.notifyErrorStateListener(error)
          }

          return Promise.reject(error)
        }

        if (!this.isRefreshingAccessToken) {
          this.isRefreshingAccessToken = true
          this.auth
            .refreshAccessToken()
            .then(() => {
              this.isRefreshingAccessToken = false
              this.retryRequestQueues(null)
            })
            .catch((error: AxiosError) => {
              this.isRefreshingAccessToken = false
              this.auth.signOut()
              this.retryRequestQueues(error)
            })
        }

        return this.retry(error)
      }
    )
  }

  configWithAuthorization(config: AxiosRequestConfig, token: string | null) {
    if (!token) return config

    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    }
  }

  onErrorStateChange(listener: (error: AxiosError | null) => void) {
    this.errorStateListener.push(listener)

    return () => this.errorStateListener.filter(l => listener !== l)
  }

  notifyErrorStateListener(error: AxiosError | null) {
    this.errorStateListener.forEach(listener => listener(error))
  }

  isAccessTokenExpired(error: AxiosError) {
    return (
      error.response?.status === UNAUTHORIZED_CODE &&
      !error.config.url?.startsWith('/auth/')
    )
  }

  retry({ config }: AxiosError) {
    return new Promise((resolve, reject) => {
      this.retryRequestTasks.push(error => {
        if (error) {
          reject(error)
        } else {
          resolve(
            this.axios.request(
              this.configWithAuthorization(config, this.auth.accessToken)
            )
          )
        }
      })
    })
  }

  retryRequestQueues(error: AxiosError | null) {
    this.retryRequestTasks.forEach(queue => queue(error))
    this.retryRequestTasks = []
  }
}

export default Apis

import React from 'react'

import { IconProps } from './types'

const CameraIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      height={size}
      width={size}
      {...props}
    >
      <g>
        <path
          fill='none'
          d='M0 0H14V14H0z'
          transform='translate(-278 -154) translate(278 154)'
        />
        <g>
          <path
            fill='currentColor'
            d='M8.871 9.727a2.163 2.163 0 1 1-2.164-2.164 2.166 2.166 0 0 1 2.164 2.164zm4.544-3.072V12.8a1.483 1.483 0 0 1-1.483 1.483H1.483A1.483 1.483 0 0 1 0 12.8V6.655a1.483 1.483 0 0 1 1.483-1.484h1.825v-.513a1.3 1.3 0 0 1 1.3-1.3h4.2a1.3 1.3 0 0 1 1.3 1.3v.513h1.825a1.484 1.484 0 0 1 1.482 1.484zM9.984 9.727A3.276 3.276 0 1 0 6.707 13a3.28 3.28 0 0 0 3.277-3.273z'
            transform='translate(-278 -154) translate(278.3 152.14)'
          />
        </g>
      </g>
    </svg>
  )
}

export default CameraIcon

import IntlMessageFormat from 'intl-messageformat'
import React, { ReactNode, createContext, useContext } from 'react'

import { Language } from '@/common/i18n'

export type Translation = string | Record<string, string>
export type TranslationFn = (
  translation: Translation,
  args?: Record<string, any>
) => string

interface I18nContextValue {
  language: Language
  t: (translation: Translation, args?: Record<string, any>) => string
}

interface I18nProviderProps {
  children: ReactNode
  language: Language
}

const I18nContext = createContext<I18nContextValue | null>(null)

const useI18n = () => {
  const value = useContext(I18nContext)

  if (!value) {
    throw new Error('useI18n must be used within a I18nProvider')
  }

  return value
}

export const I18nProvider = ({ language, ...props }: I18nProviderProps) => {
  const t: TranslationFn = (
    translation: Translation,
    { _language: lang = language, ...args }: Record<string, any> = {}
  ) => {
    if (typeof translation === 'string') return translation
    return new IntlMessageFormat(translation?.[lang] ?? '').format(args)
  }

  return <I18nContext.Provider {...props} value={{ language, t }} />
}

export default useI18n

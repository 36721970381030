export const resolvePath = (
  path: string,
  params: Record<string, string | number>
) => {
  return path.replace(/:([^/]+)/g, (_, key) => String(params[key]))
}

export const isExternalUrl = (url?: string) => {
  return url ? /^((blob:)?https?:)?\/\//.test(url) : false
}

export const getUrlParams = (
  path: string,
  url: string
): Record<string, string | number> => {
  const params: Record<string, string | number> = {}
  const urlParams = url.split('/').filter(Boolean)
  const pathParams = path.split('/').filter(Boolean)

  pathParams.forEach((pathParam, index) => {
    if (pathParam.startsWith(':')) {
      const value = Number(urlParams[index])
      params[pathParam.slice(1)] = Number.isNaN(value)
        ? urlParams[index]
        : value
    }
  })

  return params
}

export const getUrlExtension = (url?: string | null) => {
  return url ? url.split(/[#?]/)[0].split('.').pop()?.trim() : undefined
}

export const getFileUrl = (path?: string | null) => {
  return path
    ? isExternalUrl(path)
      ? path
      : `${process.env.RAZZLE_FILE_PREFIX_URL}/${path}`
    : undefined
}

import loadable from '@loadable/component'
import qs from 'qs'
import React, { useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Container from '@/common/components/Container'
import Gap from '@/common/components/Gap'
import Link, { LinkProps } from '@/common/components/Link'
import { PROJECT_NAME } from '@/common/constants'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import usePermission from '@/common/hooks/usePermission'
import useSwitch from '@/common/hooks/useSwitch'
import logo from '@/common/images/logo.svg'
import { HOME_PATH, CONTACT_PATH } from '@/common/paths'
import { CONTACT } from '@/common/translation'
import { ARTICLES } from '@/modules/article/translation'
import getArticlesUrl from '@/modules/article/utils/getArticlesUrl'
import useCurrentUser from '@/modules/authentication/hooks/useCurrentUser'
import { SIGN_IN_PATH } from '@/modules/authentication/paths'
import { SIGN_IN } from '@/modules/authentication/translation'
import { BROKER_REGISTER_PATH } from '@/modules/broker/paths'
import { BROKER_REGISTER } from '@/modules/broker/translation'
import { OWNER_REGISTER_PATH } from '@/modules/owner/paths'
import { OWNER_REGISTER } from '@/modules/owner/translation'
import { FIND_PLACE } from '@/modules/property/translation'

import Hamburger from './components/Hamburger'
import MyProfile from './components/MyProfile'
import { Wrapper, Logo, SignInButton } from './styled'

const MenuDrawer = loadable(() => import('./components/MenuDrawer'), {
  ssr: false,
})

interface ItemProps extends Omit<LinkProps, 'className' | 'to'> {
  path: string
  label: Translation
}

const Header = () => {
  const { t, language } = useI18n()
  const location = useLocation()
  const { data: currentUser } = useCurrentUser()
  const { isBroker, isOwner } = usePermission()
  const { value: isOpenMenu, toggle: toggleMenu, off: closeMenu } = useSwitch()

  const leftNavItems = useMemo(
    () => [
      {
        path: HOME_PATH,
        label: FIND_PLACE,
        onClick: () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      },
      { path: getArticlesUrl({ language }), label: ARTICLES },
      { path: CONTACT_PATH, label: CONTACT },
    ],
    [language]
  ) as ItemProps[]

  const rightNavItems = useMemo(
    () =>
      [
        !isOwner && { path: OWNER_REGISTER_PATH, label: OWNER_REGISTER },
        !isBroker && { path: BROKER_REGISTER_PATH, label: BROKER_REGISTER },
      ].filter(Boolean),
    [isBroker, isOwner]
  ) as ItemProps[]

  const signInPath = `${SIGN_IN_PATH}?${qs.stringify({
    r: location.pathname + location.search,
  })}`

  const renderNavItems = (key: string, items: ItemProps[]) =>
    items.map(({ path, label, ...props }, index) => (
      <Link
        key={`header-item-${key}-${index}`}
        to={path}
        className='text-14 text-medium none-tablet'
        {...props}
      >
        {t(label)}
      </Link>
    ))

  useEffect(() => {
    closeMenu()
  }, [location.pathname, closeMenu])

  return (
    <>
      <Wrapper>
        <Container className='flex justify-space-between px-16-mobile'>
          <Gap className='align-center' space={40}>
            <Gap className='align-center' space={16}>
              <Hamburger
                className='none block-tablet'
                active={isOpenMenu}
                onClick={toggleMenu}
              />
              <Link to={HOME_PATH}>
                <Gap className='align-center' space={14}>
                  <Logo src={logo} alt='logo' />
                  <div className='text-16 text-bold text-nowrap'>
                    {PROJECT_NAME}
                  </div>
                </Gap>
              </Link>
            </Gap>
            {renderNavItems('left-menu', leftNavItems)}
          </Gap>
          <Gap className='align-center' space={40}>
            {renderNavItems('right-menu', rightNavItems)}
            {currentUser ? (
              <MyProfile />
            ) : (
              <SignInButton to={signInPath}>{t(SIGN_IN)}</SignInButton>
            )}
          </Gap>
        </Container>
      </Wrapper>
      {isOpenMenu && <MenuDrawer onClose={toggleMenu} />}
    </>
  )
}

export default Header

import axios, { AxiosRequestConfig } from 'axios'
import moment from 'moment'
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Apis from '@/common/apis'
import { COOKIE_KEY_ACCESS_TOKEN } from '@/common/apis/auth'
import MainPageLayout from '@/common/components/MainPageLayout'
import ThemeProvider from '@/common/components/ThemeProvider'
import Toaster from '@/common/components/Toaster'
import { ApisProvider } from '@/common/hooks/useApis'
import { GlobalStateProvider } from '@/common/hooks/useGlobalState'
import { I18nProvider } from '@/common/hooks/useI18n'
import { LoadingOverlayProvider } from '@/common/hooks/useLoadingOverlay'
import { Language } from '@/common/i18n'
import { ApiCookie } from '@/common/types'
import { isSSR } from '@/common/utils/server'
import { AuthenticationProvider } from '@/modules/authentication/hooks/useAuthentication'
import authenticationRoutes from '@/modules/authentication/routes'
import brokerRoutes from '@/modules/broker/routes'
import landingRoutes from '@/modules/landing/routes'
import meRoutes from '@/modules/me/routes'
import othersRoutes from '@/modules/others/routes'
import ownerRoutes from '@/modules/owner/routes'
import propertyRoutes from '@/modules/property/routes'

import '@/common/yup'

interface AppProps {
  apis: Apis
  language: Language
  cookie: ApiCookie
}

interface RouteConfig {
  path: string
  element: JSX.Element
  routes?: RouteConfig[]
}

const routes = [
  ...authenticationRoutes,
  ...landingRoutes,
  ...propertyRoutes,
  ...meRoutes,
  ...brokerRoutes,
  ...ownerRoutes,
  ...othersRoutes,
]

const renderRoutes = (config: RouteConfig[]) =>
  config.map(({ routes = [], ...props }) => (
    <Route key={props.path} {...props}>
      {renderRoutes(routes)}
    </Route>
  ))

const App = ({ apis, language, cookie }: AppProps) => {
  moment.locale(language)

  return (
    <I18nProvider language={language}>
      <ApisProvider apis={apis}>
        <AuthenticationProvider
          isAuthenticated={!!cookie.values[COOKIE_KEY_ACCESS_TOKEN]}
        >
          <ThemeProvider>
            <LoadingOverlayProvider>
              <GlobalStateProvider>
                <MainPageLayout>
                  <Routes>{renderRoutes(routes)}</Routes>
                </MainPageLayout>
              </GlobalStateProvider>
            </LoadingOverlayProvider>
            <Toaster />
          </ThemeProvider>
        </AuthenticationProvider>
      </ApisProvider>
    </I18nProvider>
  )
}

export const getAppProps = ({
  apisRequestConfig,
  ...props
}: Omit<AppProps, 'apis'> & {
  apisRequestConfig?: Partial<AxiosRequestConfig>
}) => ({
  ...props,
  apis: new Apis(
    axios.create({ baseURL: isSSR() ? process.env.RAZZLE_API_URL : '/_api' }),
    props.cookie,
    apisRequestConfig ?? {}
  ),
})

export default App

export const SEX_OPTIONS = [
  {
    value: 'MALE',
    label: { en: 'Male', th: 'ชาย' },
  },
  {
    value: 'FEMALE',
    label: { en: 'Female', th: 'หญิง' },
  },
]

export const LANGUAGE_OPTIONS = [
  {
    value: 'THAI',
    label: { en: 'Thai', th: 'ภาษาไทย' },
  },
  {
    value: 'ENGLISH',
    label: { en: 'English', th: 'ภาษาอังกฤษ' },
  },
  {
    value: 'CHINESE',
    label: { en: 'Chinese', th: 'ภาษาจีน' },
  },
  {
    value: 'JAPANESE',
    label: { en: 'Japanese', th: 'ภาษาญี่ปุ่น' },
  },
]

export const BEDROOM_OPTIONS = [
  {
    value: 0,
    label: { en: 'Studio', th: 'สตูดิโอ' },
  },
  {
    value: 1,
    label: { en: '1 Bedroom', th: '1 ห้องนอน' },
  },
  {
    value: 2,
    label: { en: '2 Bedrooms', th: '2 ห้องนอน' },
  },
  {
    value: 3,
    label: { en: '3 Bedrooms', th: '3 ห้องนอน' },
  },
  {
    value: 4,
    label: { en: '> 3 Bedrooms', th: '3 ห้องนอนขึ้นไป' },
  },
]

export const OCCUPANT_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
]

export const PLACE_SCORE_OPTIONS = [
  { value: 'commuteScore', label: { en: 'Commute', th: 'การเดินทาง' } },
  { value: 'facilityScore', label: { en: 'Facility', th: 'ส่วนกลาง' } },
  { value: 'convenientScore', label: { en: 'Convenient', th: 'ความสะดวก' } },
  { value: 'priceScore', label: { en: 'Price', th: 'ราคา' } },
]

export const RATING_PROPERTY_OPTIONS = [
  {
    value: 'factorCommute',
    label: { en: 'Ease in commuting', th: 'ระบบการจัดส่ง' },
  },
  {
    value: 'factorFoodShop',
    label: { en: 'Ease in finding food or shops', th: 'สิ่งอำนวยความสะดวก' },
  },
  { value: 'factorSafety', label: { en: 'Safety', th: 'ความปลอดภัย' } },
  { value: 'factorPrice', label: { en: 'Price', th: 'ราคาเช่า' } },
  { value: 'factorOther', label: { en: 'Others', th: 'อื่นๆ' } },
]

export const RATING_BROKER_OPTIONS = [
  { value: 'factorService', label: { en: 'Service', th: 'การบริการ' } },
  {
    value: 'factorResponse',
    label: { en: 'Speed of response', th: 'ความเร็วในการตอบ' },
  },
  {
    value: 'factorInfo',
    label: { en: 'Amount of information', th: 'ข้อมูลที่ได้รับ' },
  },
  { value: 'factorSincerity', label: { en: 'Sincerity', th: 'ความจริงใจ' } },
  { value: 'factorOther', label: { en: 'Others', th: 'อื่นๆ' } },
]

export const RATING_TENANT_OPTIONS = [
  {
    value: 'factorPayment',
    label: { en: 'Payment', th: 'จ่ายค่าเช่าครบถ้วน' },
  },
  {
    value: 'factorPunctual',
    label: { en: 'Punctual payment', th: 'จ่ายค่าเช่าตรงเวลา' },
  },
  {
    value: 'factorCondition',
    label: { en: 'Preserve property', th: 'การรักษาทรัพย์สิน' },
  },
  { value: 'factorPolitness', label: { en: 'Politeness', th: 'ความสุภาพ' } },
  { value: 'factorOther', label: { en: 'Others', th: 'อื่นๆ' } },
]

export const REPORT_PROPERTY_REASON_OPTIONS = [
  {
    value: 'unavailble',
    label: { en: 'Unavailable', th: 'ไม่พร้อมใช้งาน' },
  },
  {
    value: 'notOwner',
    label: { en: 'Not the owner', th: 'ไม่ใช่เจ้าของห้องจริง' },
  },
  {
    value: 'notRoom',
    label: { en: 'Not the actual room', th: 'ไม่ใช่สภาพห้องจริง' },
  },
  {
    value: 'notPrice',
    label: { en: 'Not the actual price', th: 'ไม่ใช่ราคาห้องจริง' },
  },
  {
    value: 'other',
    label: { en: 'Others', th: 'อื่นๆ' },
  },
]

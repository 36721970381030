import React, { ReactNode } from 'react'
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components'

import theme from '@/theme'

import globalStyle from './globalStyle'

interface ThemeProviderProps {
  children: ReactNode
}

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      <div id='theme-root'>{children}</div>
    </StyledThemeProvider>
  )
}

export default ThemeProvider

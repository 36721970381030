import moment from 'moment'

import { ApiClient, ApiCookie, ApiRequest } from '@/common/types'

export const COOKIE_KEY_ACCESS_TOKEN = 'act'

class AuthApis {
  private _accessToken: string | null = null
  private authStateListener: ((token: string | null) => void)[] = []

  public signIn: ApiRequest<
    void,
    {
      email: string
      password: string
      rememberMe?: boolean
    }
  >
  public socialSignIn: ApiRequest<void, { token: string }>
  public signUp: ApiRequest<
    void,
    {
      email: string
      password: string
      confirmPassword: string
      firstName: string
      lastName: string
    }
  >
  public signOut: ApiRequest<void>
  public refreshAccessToken: ApiRequest<void>
  public verifyEmail: ApiRequest<void, { token: string }>
  public forgotPassword: ApiRequest<void, { email: string }>
  public resetPassword: ApiRequest<
    void,
    {
      token: string
      password: string
      confirmPassword: string
    }
  >

  constructor({ createRequest }: ApiClient, private cookie: ApiCookie) {
    this._accessToken = cookie.values[COOKIE_KEY_ACCESS_TOKEN] ?? null

    this.signIn = createRequest({
      method: 'post',
      url: () => '/auth/login',
      payload: ({ email, password }) => ({ email, password }),
      callback: (response, props) => {
        this.setAccessToken(response.data.token, props.rememberMe)
      },
    })

    this.socialSignIn = createRequest({
      method: 'get',
      url: ({ token }) => `/auth/social/${token}`,
      callback: response => {
        this.setAccessToken(response.data.token)
      },
    })

    this.signUp = createRequest({
      method: 'post',
      url: () => '/auth/signup',
      payload: props => props,
      callback: response => {
        this.setAccessToken(response.data.token)
      },
    })

    this.signOut = createRequest({
      method: 'delete',
      url: () => '/auth/signout',
      callback: () => {
        this.setAccessToken(null)
      },
      onRejected: () => {
        this.setAccessToken(null)
      },
    })

    this.refreshAccessToken = createRequest({
      method: 'post',
      url: () => '/auth/refresh',
      callback: response => {
        this.setAccessToken(response.data.token)
      },
    })

    this.verifyEmail = createRequest({
      method: 'post',
      url: ({ token }) => `/auth/email?auth=${token}`,
    })

    this.forgotPassword = createRequest({
      method: 'patch',
      url: () => '/auth/password/forgot',
      payload: props => props,
    })

    this.resetPassword = createRequest({
      method: 'patch',
      url: ({ token }) => `/auth/password/reset/${token}`,
      payload: ({ token, ...props }) => props,
    })
  }

  get accessToken() {
    return this._accessToken
  }

  setAccessToken(token: string | null, save = true) {
    if (!token) {
      this._accessToken = null
      this.cookie.remove(COOKIE_KEY_ACCESS_TOKEN)
      this.notifyAuthStateListener(null)
      return
    }

    this._accessToken = token
    this.cookie.set(COOKIE_KEY_ACCESS_TOKEN, token, {
      expires: save ? moment().add(1, 'years').toDate() : undefined,
    })
    this.notifyAuthStateListener(token)
  }

  onAuthStateChange(listener: (token: string | null) => void) {
    this.authStateListener.push(listener)

    return () => this.authStateListener.filter(l => listener !== l)
  }

  notifyAuthStateListener(token: string | null) {
    this.authStateListener.forEach(listener => listener(token))
  }
}

export default AuthApis

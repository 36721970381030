import styled from 'styled-components'

import DropDown from '@/common/components/DropDown'
import Gap from '@/common/components/Gap'
import Link from '@/common/components/Link'

export const Wrapper = styled(Gap).attrs({
  className: 'align-center',
  space: 12,
})`
  max-width: 200px;
`

export const StyledDropDown = styled(DropDown)`
  z-index: 1000 !important;
  position: fixed !important;
  width: auto !important;
  min-width: 200px !important;
`

export const MenuItem = styled(Link).attrs({
  className: 'text-14 text-medium clickable px-20 py-8',
})`
  display: block;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

import loadable from '@loadable/component'
import React from 'react'

import { SEARCH_PLACE_PATH, PLACE_DETAIL_PATH, ROOM_DETAIL_PATH } from './paths'

const SearchPlacePage = loadable(() => import('./pages/SearchPlacePage'))
const PlaceDetailPage = loadable(() => import('./pages/PlaceDetailPage'))
const RoomDetailPage = loadable(() => import('./pages/RoomDetailPage'))

export default [
  { path: SEARCH_PLACE_PATH, element: <SearchPlacePage /> },
  { path: PLACE_DETAIL_PATH, element: <PlaceDetailPage /> },
  { path: ROOM_DETAIL_PATH, element: <RoomDetailPage /> },
]

import React from 'react'

import { IconProps } from './types'

const CloseIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      height={size}
      width={size}
      {...props}
    >
      <path
        fill='currentColor'
        d='M9.466 8.136l6.23-6.23A1.036 1.036 0 0 0 14.23.44L8 6.67 1.77.44A1.036 1.036 0 0 0 .3 1.905l6.23 6.23L.3 14.366a1.036 1.036 0 1 0 1.47 1.466L8 9.6l6.23 6.23a1.036 1.036 0 0 0 1.47-1.464zm0 0'
        transform='translate(0 -.136)'
      />
    </svg>
  )
}

export default CloseIcon

import { colord } from 'colord'
import styled from 'styled-components'

import Gap from '@/common/components/Gap'

interface ContainerProps {
  $isError?: boolean
}

export const Container = styled(Gap).attrs({
  className:
    'align-center justify-center text-14 text-medium text-center radius-8 p-24 clickable',
  space: 4,
  vertical: true,
})<ContainerProps>`
  background-color: #fefaff;
  border: 1px dashed
    ${({ theme, $isError }) =>
      $isError
        ? theme.colors.error
        : colord(theme.colors.primary).alpha(0.1).toHex()};
`

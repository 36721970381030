import { Language } from '@/common/i18n'
import { ARTICLE_LIST_PATH } from '@/modules/article/paths'

interface GetArticlesUrlParams {
  language: Language
}

const getArticlesUrl = ({ language }: GetArticlesUrlParams) =>
  ARTICLE_LIST_PATH + (language === 'th' ? '?lang=th' : '')

export default getArticlesUrl

export const MY_PROFILE = { en: 'My profile', th: 'โปรไฟล์ของฉัน' }

export const PERSONAL_INFORMATION = {
  en: 'Personal information',
  th: 'ข้อมูลส่วนตัว',
}

export const RENTAL_HISTORY = { en: 'Rental history', th: 'ประวัติการเช่า' }

export const RENTAL_HISTORY_PAYMENTS = { en: 'Payments', th: 'หลักฐานโอนเงิน' }

export const SAVED_LISTINGS = { en: 'Saved listings', th: 'ห้องที่ชอบ' }

export const ADVANCED_SEARCH_HISTORY = {
  en: 'Advanced search history',
  th: 'ประวัติการค้นหาขั้นสูง',
}

export const BROKER_PROFILE = { en: 'Broker profile', th: 'ข้อมูลนายหน้า' }

export const BROKER_LISTINGS = {
  en: 'Broker listings',
  th: 'ห้องที่เป็นนายหน้า',
}

export const OWNER_PROFILE = { en: 'Owner profile', th: 'ข้อมูลเจ้าของที่พัก' }

export const OWNER_LISTINGS = { en: 'My listings', th: 'ที่พักของฉัน' }

export const CREATE_LISTING = { en: 'Create listing', th: 'สร้างประกาศ' }

export const REGISTER_AS_BROKER = {
  en: 'Register as broker',
  th: 'สมัครเป็นนายหน้า',
}

export const REGISTER_AS_OWNER = {
  en: 'List my property (Owner)',
  th: 'ลงประกาศเช่า (เจ้าของ)',
}

export const PAYMENTS = { en: 'Payments', th: 'ประวัติการจ่ายเงิน' }

export const BROKER_LIST = { en: 'Broker list', th: 'รายชื่อนายหน้า' }

export const DEALS = { en: 'Deals', th: 'ห้องที่เคยปล่อยเช่า' }

import { loadableReady } from '@loadable/component'
import Cookies, { CookieAttributes } from 'js-cookie'
import React from 'react'
import { hydrate } from 'react-dom'
import { Hydrate, QueryClientProvider, QueryClient } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import App, { getAppProps } from '@/App'
import { determineUserLang } from '@/common/i18n'

loadableReady().then(() => {
  const { code: language, basename } = determineUserLang(
    Cookies.get(),
    navigator.languages,
    window.location.pathname
  )

  const dehydratedState = (window as any).__REACT_QUERY_STATE__
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  })

  hydrate(
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <BrowserRouter basename={basename}>
          <App
            {...getAppProps({
              language,
              cookie: {
                values: Cookies.get(),
                set: (key: string, value: string, options?: CookieAttributes) =>
                  Cookies.set(key, value, options),
                remove: (key: string) => Cookies.remove(key),
              },
            })}
          />
        </BrowserRouter>
      </Hydrate>
    </QueryClientProvider>,
    document.getElementById('root')
  )
})

if (module.hot) {
  module.hot.accept()
}

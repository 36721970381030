import qs from 'qs'
import { useMemo, useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import useCurrentUser from '@/modules/authentication/hooks/useCurrentUser'
import { SIGN_IN_PATH } from '@/modules/authentication/paths'
import { BROKER_REGISTER_PATH } from '@/modules/broker/paths'
import { OWNER_REGISTER_PATH } from '@/modules/owner/paths'

type Role = 'member' | 'owner' | 'broker'

interface Response {
  isMember: boolean
  isOwner: boolean
  isBroker: boolean
  hasPermission: (role: Role) => boolean
  guard: (role: Role, callback?: () => void) => void
}

const REDIRECT_PATH = {
  member: SIGN_IN_PATH,
  owner: OWNER_REGISTER_PATH,
  broker: BROKER_REGISTER_PATH,
}

const usePermission = (): Response => {
  const { data: currentUser } = useCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { isAuthenticated, Owner: owner, Broker: broker } = currentUser ?? {}
  const currentPermissions = useMemo(
    () => ({
      isMember: !!isAuthenticated,
      isOwner: !!owner?.isApproved,
      isBroker: !!broker?.isApproved,
    }),
    [isAuthenticated, broker?.isApproved, owner?.isApproved]
  )

  const hasPermission = useCallback(
    (role: Role) =>
      currentPermissions[`is${role[0].toUpperCase()}${role.slice(1)}`],
    [currentPermissions]
  )

  const guard = useCallback(
    (role: Role, callback?: () => void) => {
      const redirectTo =
        searchParams.get('r') ?? location.pathname + location.search

      if (hasPermission(role)) {
        callback?.()
      } else {
        navigate({
          pathname: REDIRECT_PATH[role],
          search: `?${qs.stringify({ r: redirectTo })}`,
        })
      }
    },
    [location, searchParams, hasPermission, navigate]
  )

  return useMemo(
    () => ({ ...currentPermissions, hasPermission, guard }),
    [currentPermissions, hasPermission, guard]
  )
}

export default usePermission

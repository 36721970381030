import styled from 'styled-components'

import ButtonLink from '@/common/components/ButtonLink'
import Image from '@/common/components/Image'

export const HEADER_HEIGHT = 56

export const Wrapper = styled.div`
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
`

export const Logo = styled(Image).attrs({
  width: 33,
  height: 33,
})`
  position: relative;
  top: -3px;
`

export const SignInButton = styled(ButtonLink).attrs({
  buttonClassName: 'text-14 px-16',
  variant: 'filled',
})`
  > button {
    height: 26px;
  }
`

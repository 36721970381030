import React, { ComponentPropsWithRef } from 'react'

import { StyledImage, NoImage } from './styled'

interface ImageProps extends Omit<ComponentPropsWithRef<'img'>, 'src'> {
  src?: string | null
  cover?: boolean
  width?: string | number
  height?: string | number
  tabletWidth?: string | number
  tabletHeight?: string | number
  mobileWidth?: string | number
  mobileHeight?: string | number
}

const Image = ({
  className,
  src,
  cover = false,
  width,
  height,
  tabletWidth = width,
  tabletHeight = height,
  mobileWidth = tabletWidth,
  mobileHeight = tabletHeight,
  ...props
}: ImageProps) => {
  return src ? (
    <StyledImage
      {...props}
      className={className}
      src={src}
      $cover={cover}
      $width={width}
      $height={height}
      $tabletWidth={tabletWidth}
      $tabletHeight={tabletHeight}
      $mobileWidth={mobileWidth}
      $mobileHeight={mobileHeight}
    />
  ) : (
    <NoImage
      className={className}
      $width={width}
      $height={height}
      $tabletWidth={tabletWidth}
      $tabletHeight={tabletHeight}
      $mobileWidth={mobileWidth}
      $mobileHeight={mobileHeight}
    />
  )
}

export default Image

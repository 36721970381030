import React, { ComponentType, useMemo } from 'react'

import Container from '@/common/components/Container'
import Gap from '@/common/components/Gap'
import { LinkProps } from '@/common/components/Link'
import LineIcon from '@/common/components/icons/LineIcon'
import MailIcon from '@/common/components/icons/MailIcon'
import { IconProps } from '@/common/components/icons/types'
import {
  PROJECT_NAME,
  ADDRESS,
  ADRRESS_MAP_URL,
  EMAIL,
  LINE_ID,
} from '@/common/constants'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import { HOME_PATH, CONTACT_PATH } from '@/common/paths'
import { HOME, CONTACT, COMPANY_ADDERSS } from '@/common/translation'
import { ARTICLES } from '@/modules/article/translation'
import getArticlesUrl from '@/modules/article/utils/getArticlesUrl'
import { SIGN_IN_PATH } from '@/modules/authentication/paths'
import { SIGN_IN } from '@/modules/authentication/translation'
import { BROKER_REGISTER_PATH } from '@/modules/broker/paths'
import { BROKER_REGISTER } from '@/modules/broker/translation'
import { OWNER_REGISTER_PATH } from '@/modules/owner/paths'
import { OWNER_REGISTER } from '@/modules/owner/translation'
import { FIND_PLACE } from '@/modules/property/translation'

import Copyright from './components/Copyright'
import background from './images/background.svg'
import { Wrapper, Background, Topic, Item } from './styled'

const CONTACT_LIST = [
  {
    icon: MailIcon,
    path: `mailto:${EMAIL}`,
    label: EMAIL,
    external: true,
  },
  {
    icon: LineIcon,
    path: `https://line.me/R/ti/p/${LINE_ID}`,
    label: LINE_ID,
    external: true,
  },
]

const ADRESS_LIST = [{ path: ADRRESS_MAP_URL, label: ADDRESS, external: true }]

interface ItemProps extends Omit<LinkProps, 'className' | 'to'> {
  path: string
  label: Translation
  icon?: ComponentType<IconProps>
}

const Footer = () => {
  const { t, language } = useI18n()

  const menuItems = useMemo(
    () => [
      { path: HOME_PATH, label: HOME },
      {
        path: HOME_PATH,
        label: FIND_PLACE,
        onClick: () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      },
      { path: getArticlesUrl({ language }), label: ARTICLES },
      { path: CONTACT_PATH, label: CONTACT },
      { path: SIGN_IN_PATH, label: SIGN_IN },
      { path: OWNER_REGISTER_PATH, label: OWNER_REGISTER },
      { path: BROKER_REGISTER_PATH, label: BROKER_REGISTER },
    ],
    [language]
  ) as ItemProps[]

  const leftMenuItems = useMemo(
    () => menuItems.slice(0, Math.ceil(menuItems.length / 2)),
    [menuItems]
  )
  const rightMenuItems = useMemo(
    () => menuItems.slice(Math.ceil(menuItems.length / 2)),
    [menuItems]
  )

  const renderItems = (key: string, items: ItemProps[]) => (
    <Gap space={10} vertical>
      {items.map(({ path, label, icon: Icon, ...props }, index) => (
        <Item key={`footer-item-${key}-${index}`} to={path} {...props}>
          {Icon && <Icon className='mr-12' />}
          {t(label)}
        </Item>
      ))}
    </Gap>
  )

  return (
    <>
      <Wrapper>
        <Container className='py-40 px-40-tablet'>
          <Background src={background} alt='background' />
          <Gap space={40} mobileVertical>
            <div className='flex-1'>
              <Topic>{PROJECT_NAME}</Topic>
              <Gap space={140} tabletSpace={100} mobileSpace={120}>
                {renderItems('menu-left', leftMenuItems)}
                {renderItems('menu-right', rightMenuItems)}
              </Gap>
            </div>
            <div className='flex-1'>
              <Gap space={40} tabletVertical>
                <div className='flex-1'>
                  <Topic>{t(CONTACT)}</Topic>
                  {renderItems('contact', CONTACT_LIST)}
                </div>
                <div className='flex-1'>
                  <Topic>{t(COMPANY_ADDERSS)}</Topic>
                  {renderItems('address', ADRESS_LIST)}
                </div>
              </Gap>
            </div>
          </Gap>
        </Container>
      </Wrapper>
      <Copyright />
    </>
  )
}

export default Footer

import { SelectOption } from '@/common/components/SelectField'
import { ApiClient, ApiRequest } from '@/common/types'

class OwnerApis {
  public register: ApiRequest<RentAPerfect.Owner, { document: File }>
  public getProfile: ApiRequest<RentAPerfect.Owner>
  public updateProfile: ApiRequest<RentAPerfect.Owner, { document: File }>
  public getRooms: ApiRequest<
    RentAPerfect.OwnerRoom[],
    {
      keyword?: string
      roomStatus?: string
      roomApprovalStatus?: string
      price?: [number, number]
    }
  >
  public getRoom: ApiRequest<RentAPerfect.OwnerRoom, { roomId: string }>
  public createRoom: ApiRequest<
    RentAPerfect.OwnerRoom,
    RentAPerfect.OwnerRoom & {
      images: File[]
      titleDeeds: File[]
    }
  >
  public updateRoom: ApiRequest<
    RentAPerfect.OwnerRoom,
    Partial<RentAPerfect.OwnerRoom> & {
      roomId: string
      images?: File[]
      titleDeeds?: File[]
      deletedImageIds?: number[]
      deletedTitleDeedIds?: number[]
    }
  >
  public renewRoom: ApiRequest<RentAPerfect.OwnerRoom, { roomId: string }>
  public deleteRoom: ApiRequest<void, { roomId: string }>
  public getRentalOptions: ApiRequest<
    {
      renters: SelectOption<string>[]
      brokers: SelectOption<string>[]
    },
    { roomId: string }
  >
  public createUserContract: ApiRequest<
    RentAPerfect.UserContract,
    {
      roomId: string
      dateStart: string
      dateExpired: string
      payDate: [number, number]
      price: number
      renterId?: string
      brokerId?: string
    }
  >
  public updateUserContract: ApiRequest<
    RentAPerfect.UserContract,
    {
      contractId: number
      dateStart: string
      dateExpired: string
      payDate: [number, number]
      price: number
      renterId?: string
      brokerId?: string
    }
  >
  public getUserContractPayments: ApiRequest<
    RentAPerfect.Payment[],
    { contractId: number }
  >
  public approveContractExtension: ApiRequest<void, { contractId: number }>
  public rejectContractExtension: ApiRequest<void, { contractId: number }>
  public approveBroker: ApiRequest<void, { brokerListingId: number }>
  public rejectBroker: ApiRequest<void, { brokerListingId: number }>

  constructor({ createRequest }: ApiClient) {
    this.register = createRequest({
      method: 'post',
      isFormData: true,
      url: () => '/owner',
      payload: ({ document }) => {
        const formData = new FormData()
        formData.append('files', document)
        return formData
      },
    })

    this.getProfile = createRequest({
      method: 'get',
      url: () => '/owner',
    })

    this.updateProfile = createRequest({
      method: 'patch',
      isFormData: true,
      url: () => '/owner',
      payload: ({ document }) => {
        const formData = new FormData()
        formData.append('files', document)
        return formData
      },
    })

    this.getRooms = createRequest({
      method: 'get',
      url: () => '/owner/room',
      payload: ({ keyword, price, ...props }) => ({
        ...props,
        placeDetail: keyword,
        minPrice: price?.[0],
        maxPrice: price?.[1],
      }),
      getResponseData: response => response.data.rooms,
    })

    this.getRoom = createRequest({
      method: 'get',
      url: ({ roomId }) => `/owner/room/${roomId}`,
    })

    this.createRoom = createRequest({
      method: 'post',
      isFormData: true,
      url: () => '/owner/room',
      payload: ({ images, titleDeeds, ...room }) => {
        const formData = new FormData()
        formData.append('room', JSON.stringify(room))

        images.forEach(file => formData.append('files', file))
        titleDeeds.forEach(file => formData.append('privateFiles', file))

        return formData
      },
    })

    this.updateRoom = createRequest({
      method: 'patch',
      isFormData: true,
      url: ({ roomId }) => `/owner/room/${roomId}`,
      payload: ({
        roomId,
        images = [],
        titleDeeds = [],
        deletedImageIds = [],
        deletedTitleDeedIds = [],
        ...room
      }) => {
        const formData = new FormData()
        formData.append('room', JSON.stringify(room))

        images.forEach(file => formData.append('files', file))
        titleDeeds.forEach(file => formData.append('privateFiles', file))

        formData.append(
          'delete',
          JSON.stringify({
            photosId: deletedImageIds,
            deedsId: deletedTitleDeedIds,
          })
        )

        return formData
      },
    })

    this.renewRoom = createRequest({
      method: 'patch',
      url: ({ roomId }) => `/owner/room/${roomId}/renew`,
    })

    this.deleteRoom = createRequest({
      method: 'delete',
      url: ({ roomId }) => `/owner/room/${roomId}`,
    })

    this.getRentalOptions = createRequest({
      method: 'get',
      url: ({ roomId }) => `/owner/contract/${roomId}`,
      getResponseData: response => ({
        renters: response.data.renters.map(user => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        })),
        brokers: response.data.brokers.map(broker => ({
          value: broker.id,
          label: `${broker.firstName} ${broker.lastName}`,
        })),
      }),
    })

    this.createUserContract = createRequest({
      method: 'post',
      url: ({ roomId }) => `/owner/contract/${roomId}`,
      payload: ({ roomId, payDate, ...props }) => ({
        ...props,
        payDateFrom: payDate[0],
        payDateTo: payDate[1],
      }),
      getResponseData: response => response.data.contract,
    })

    this.updateUserContract = createRequest({
      method: 'patch',
      url: ({ contractId }) => `/owner/contract/${contractId}`,
      payload: ({ contractId, payDate, ...props }) => ({
        ...props,
        payDateFrom: payDate[0],
        payDateTo: payDate[1],
      }),
      getResponseData: response => response.data.updatedContract,
    })

    this.getUserContractPayments = createRequest({
      method: 'get',
      url: ({ contractId }) => `/owner/payment/${contractId}`,
      getResponseData: response => response.data.receipts,
    })

    this.approveContractExtension = createRequest({
      method: 'patch',
      url: ({ contractId }) =>
        `/owner/contract/${contractId}/extension/approve`,
    })

    this.rejectContractExtension = createRequest({
      method: 'patch',
      url: ({ contractId }) => `/owner/contract/${contractId}/extension/reject`,
    })

    this.approveBroker = createRequest({
      method: 'patch',
      url: ({ brokerListingId }) =>
        `/owner/brokerList/${brokerListingId}/approve`,
    })

    this.rejectBroker = createRequest({
      method: 'delete',
      url: ({ brokerListingId }) =>
        `/owner/brokerList/${brokerListingId}/reject`,
    })
  }
}

export default OwnerApis

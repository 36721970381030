import React, { HTMLAttributes } from 'react'

import { StyledSeparator } from './styled'

interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
  color?: string
  type?: 'solid' | 'dashed'
}

const Separator = ({
  color = '#f2f2f2',
  type = 'solid',
  ...props
}: SeparatorProps) => <StyledSeparator {...props} $color={color} $type={type} />

export default Separator

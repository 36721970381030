import cn from 'classnames'
import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import { useTheme } from 'styled-components'

interface LoadingIndicatorProps {
  className?: string
  color?: string
  size?: number
}

const LoadingIndicator = ({
  className,
  size = 25,
  ...props
}: LoadingIndicatorProps) => {
  const { colors } = useTheme()

  return (
    <TailSpin
      wrapperClass={cn(className, 'justify-center')}
      color={colors.primary}
      ariaLabel='loading'
      width={size}
      height={size}
      {...props}
    />
  )
}

export default LoadingIndicator

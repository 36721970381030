import styled from 'styled-components'

import Image from '@/common/components/Image'
import Link from '@/common/components/Link'

export const Wrapper = styled.div.attrs({
  className: 'relative text-white pb-8 pb-0-tablet',
})`
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.secondary}, ${theme.colors.primary})`};
  overflow: hidden;
  z-index: 0;
`

export const Background = styled(Image)`
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: -1;
`

export const Topic = styled.div.attrs({
  className: 'text-16 text-semibold mb-12',
})``

export const Item = styled(Link).attrs({
  className: 'flex align-center text-14',
})`
  min-height: 24px;
`

export const FIND_PLACE = { en: 'Find Place', th: 'ค้นหา' }

export const SEARCH = { en: 'Search', th: 'ค้นหา' }

export const BASIC_SEARCH = { en: 'Basic Search', th: 'ค้นหาขั้นพื้นฐาน' }

export const ADVANCED_SEARCH = { en: 'Advanced Search', th: 'ค้นหาขั้นสูง' }

export const N_ROOMS = {
  en: '{n} {n, plural, =1 {room} other {rooms}}',
  th: '{n} ห้อง',
}

export const BEDROOMS = {
  en: '{n, plural, =1 {Bedroom} other {Bedrooms}}',
  th: 'ห้องนอน',
}

export const N_BEDROOMS = {
  en: '{n, plural, =0 {Studio} =1 {# Bedroom} other {# Bedrooms}}',
  th: '{n, plural, =0 {สตูดิโอ} other {# ห้องนอน}}',
}

export const BATHROOMS = {
  en: '{n, plural, =1 {Bathroom} other {Bathrooms}}',
  th: 'ห้องน้ำ',
}

export const AVAILABLE = { en: 'Available', th: 'ว่าง' }

export const UNAVAILABLE = { en: 'Unavailable', th: 'ไม่ว่าง' }

export const AVAILABLE_FROM = { en: 'Available from', th: 'ว่างอีกครั้งเมื่อ' }

export const SHOW_UNAVAILABLE = {
  en: 'Show Unavailable',
  th: 'แสดงห้องที่ไม่ว่าง',
}

export const SQ_M = { en: 'sq.m.', th: 'ตร.ม.' }

export const FURNISHED = { en: 'Furnished', th: 'ตกแต่ง' }

export const UNFURNISHED = { en: 'Empty', th: 'ว่าง' }

import React, { AnchorHTMLAttributes } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { isExternalUrl } from '@/common/utils/url'

export interface LinkProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  to?: string
  external?: boolean
  reload?: boolean
}

const Link = ({
  children,
  to,
  external = isExternalUrl(to),
  reload,
  ...props
}: LinkProps) => {
  return external || reload || !to ? (
    <a
      href={to}
      {...(external && { target: '_blank', rel: 'noopener noreferrer' })}
      {...props}
    >
      {children}
    </a>
  ) : (
    <RouterLink {...props} to={to}>
      {children}
    </RouterLink>
  )
}

export default Link

import bytes from 'bytes'
import cn from 'classnames'
import isArray from 'lodash/isArray'
import uniq from 'lodash/uniq'
import React from 'react'
import { useDropzone, DropzoneOptions, FileRejection } from 'react-dropzone'
import toast from 'react-hot-toast'

import FieldWrapper, {
  FieldWrapperProps,
} from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import Gap from '@/common/components/Gap'
import Link from '@/common/components/Link'
import CloseIcon from '@/common/components/icons/CloseIcon'
import DownloadIcon from '@/common/components/icons/DownloadIcon'
import UploadIcon from '@/common/components/icons/UploadIcon'
import useI18n from '@/common/hooks/useI18n'
import { compressFile, CompressFileOptions } from '@/common/utils/file'

import { Container } from './styled'
import { CHOOSE_FILE, OR_DRAG_FILE, DROP_FILE_HERE } from './translation'

export const FILE_ACCEPT = {
  pdf: 'application/pdf',
  image: 'image/jpeg, image/png',
}

export interface NewFile extends File {
  compressedFile?: Promise<File>
}

export interface ExistedFile {
  id?: number | string
  url: string
  name: string
  size?: number
}

export type FileFieldValue = NewFile | ExistedFile

type FileFieldProps = DropzoneOptions &
  FieldWrapperProps & {
    types: ('image' | 'pdf')[]
    name?: string
    value?: FileFieldValue | FileFieldValue[]
    hideFileName?: boolean
    compress?: boolean
    compressOptions?: CompressFileOptions
    onChange?: (value: FileFieldValue | FileFieldValue[]) => void
    onRemove?: (value: FileFieldValue, remove: () => void) => void
  }

const FileField = ({
  types,
  name,
  value: _value,
  hideFileName,
  maxFiles = 1,
  compress = false,
  compressOptions,
  onChange,
  onRemove: _onRemove,
  ...props
}: FileFieldProps) => {
  const { t } = useI18n()
  const { setValue, watch, trigger } = useForm()

  const files: FileFieldValue[] = [name ? watch?.(name) : _value]
    .flat()
    .filter(Boolean)

  const updateValue = (value: FileFieldValue | FileFieldValue[]) => {
    if (compress) {
      if (isArray(value)) {
        value.forEach(file => {
          if (file instanceof File) {
            Object.assign(file, {
              compressedFile: compressFile(file),
              compressOptions,
            })
          }
        })
      } else if (value instanceof File) {
        Object.assign(value, {
          compressedFile: compressFile(value, compressOptions),
        })
      }
    }

    if (name) {
      setValue?.(name, value)
      trigger?.(name)
    }
    onChange?.(value)
  }

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const value =
      maxFiles === 1 ? acceptedFiles[0] : [...files, ...acceptedFiles]
    const errorMessage = uniq(
      fileRejections
        .map(({ errors }) => errors.map(({ message }) => message))
        .flat()
    ).join(', ')

    if (value) updateValue(value)
    if (errorMessage) toast.error(errorMessage)
  }

  const onRemove = (index: number) => {
    const newValue = [...files]
    newValue.splice(index, 1)
    updateValue(newValue)
  }

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    ...props,
    accept: types.map(type => FILE_ACCEPT[type]).join(', '),
    maxFiles,
    onDrop,
  })

  const renderValues = (
    values: FileFieldValue | FileFieldValue[],
    editable = false
  ) => (
    <Gap
      className={cn(
        'mt-12',
        editable ? 'text-12 text-medium' : 'text-14 text-semibold'
      )}
      space={4}
      vertical
    >
      {[values]
        .flat()
        .filter(Boolean)
        .map((file, index) => (
          <div key={`file-${file.name}-${index}`} className='flex align-center'>
            {file instanceof File || file.url ? (
              <Link
                className='text-primary'
                to={file instanceof File ? URL.createObjectURL(file) : file.url}
              >
                {!editable ? <DownloadIcon className='mr-8' size={12} /> : null}
                {file.name}
              </Link>
            ) : (
              file.name
            )}
            {file.size ? (
              <span className='text-gray text-medium ml-8'>
                ({bytes.format(file.size)})
              </span>
            ) : null}
            {editable && maxFiles !== 1 ? (
              <CloseIcon
                className='ml-8 clickable'
                size={10}
                onClick={() => {
                  if (_onRemove) _onRemove(files[index], () => onRemove(index))
                  else onRemove(index)
                }}
              />
            ) : null}
          </div>
        ))}
    </Gap>
  )

  return (
    <FieldWrapper
      {...props}
      name={name}
      value={files}
      renderValue={renderValues}
    >
      {({ isError }) => (
        <>
          <Container {...getRootProps()} $isError={isError}>
            <input {...getInputProps()} />
            <UploadIcon className='text-primary' size={32} />
            {isDragActive ? (
              <div>{t(DROP_FILE_HERE, { n: maxFiles })}</div>
            ) : (
              <div>
                <span className='text-primary text-semibold'>
                  {t(CHOOSE_FILE, { n: maxFiles })}
                </span>
                {t(OR_DRAG_FILE)}
              </div>
            )}
          </Container>
          {!hideFileName && files.length ? renderValues(files, true) : null}
        </>
      )}
    </FieldWrapper>
  )
}

export default FileField

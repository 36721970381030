import React from 'react'

import { IconProps } from './types'

const MailIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height={size}
      width={size}
      {...props}
    >
      <path
        fill='currentColor'
        d='M12-3178a11.921 11.921 0 0 1-8.485-3.515A11.921 11.921 0 0 1 0-3190a11.923 11.923 0 0 1 3.515-8.486A11.921 11.921 0 0 1 12-3202a11.923 11.923 0 0 1 8.486 3.515A11.922 11.922 0 0 1 24-3190a11.921 11.921 0 0 1-3.515 8.485A11.923 11.923 0 0 1 12-3178zm-6.75-15.674v7.726a.467.467 0 0 0 .482.448h12.535a.467.467 0 0 0 .482-.448v-7.659l-6.446 4.869a.505.505 0 0 1-.305.1.508.508 0 0 1-.306-.1l-6.443-4.936zm.468-.826l6.281 5.01 6.352-5a.432.432 0 0 0-.071-.014H5.718z'
        transform='translate(0 3202)'
      />
    </svg>
  )
}

export default MailIcon

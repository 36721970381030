import loadable from '@loadable/component'
import React from 'react'

import {
  SIGN_IN_PATH,
  SOCIAL_SIGN_IN_PATH,
  SIGN_UP_PATH,
  VERIFY_EMAIL_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
} from './paths'

const SignInPage = loadable(() => import('./pages/SignInPage'))
const SocialSignInPage = loadable(() => import('./pages/SocialSignInPage'))
const SignUpPage = loadable(() => import('./pages/SignUpPage'))
const VerifyEmailPage = loadable(() => import('./pages/VerifyEmailPage'))
const ForgotPasswordPage = loadable(() => import('./pages/ForgotPasswordPage'))
const ResetPasswordPage = loadable(() => import('./pages/ResetPasswordPage'))

export default [
  { path: SIGN_IN_PATH, element: <SignInPage /> },
  { path: SOCIAL_SIGN_IN_PATH, element: <SocialSignInPage /> },
  { path: SIGN_UP_PATH, element: <SignUpPage /> },
  { path: VERIFY_EMAIL_PATH, element: <VerifyEmailPage /> },
  { path: FORGOT_PASSWORD_PATH, element: <ForgotPasswordPage /> },
  { path: RESET_PASSWORD_PATH, element: <ResetPasswordPage /> },
]

import styled, { css } from 'styled-components'

import { media } from '@/common/utils/viewport'

interface StyledGapProps {
  $space: number
  $vertical: boolean
  $tabletSpace: number
  $tabletVertical: boolean
  $mobileSpace: number
  $mobileVertical: boolean
}

const applyGapStyle = (vertical: boolean, space: number) => {
  return vertical
    ? css`
        display: flex;
        flex-direction: column;

        & > *:not(:last-child) {
          margin-bottom: ${space}px;
          margin-right: 0;
        }
      `
    : css`
        display: flex;
        flex-direction: row;

        & > *:not(:last-child) {
          margin-right: ${space}px;
          margin-bottom: 0;
        }
      `
}

export const StyledGap = styled.div<StyledGapProps>`
  ${({ $vertical, $space }) => applyGapStyle($vertical, $space)}

  ${media.down('lg')`
    ${({ $tabletVertical, $tabletSpace }) =>
      applyGapStyle($tabletVertical, $tabletSpace)}
  `}

  ${media.down('sm')`
    ${({ $mobileVertical, $mobileSpace }) =>
      applyGapStyle($mobileVertical, $mobileSpace)}
  `}
`

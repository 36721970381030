export const HOME = { en: 'Home', th: 'Home' }

export const CONTACT = { en: 'Contact', th: 'ติดต่อ' }

export const COMPANY_ADDERSS = { en: 'Company Address', th: 'ที่อยู่บริษัท' }

export const PRIVACY_POLICY = {
  en: 'Privacy policy',
  th: 'นโยบายความเป็นส่วนตัว',
}

export const TERMS_AND_CONDITIONS = {
  en: 'Terms and conditions',
  th: 'เงื่อนไขและข้อตกลง',
}

export const ALL = { en: 'All', th: 'ทั้งหมด' }

export const VIEW_ALL = { en: 'View all', th: 'ดูทั้งหมด' }

export const READ_MORE = { en: 'Read more', th: 'อ่านเพิ่มเติม' }

export const SAVE_SUCCESS_MESSAGE = {
  en: 'Success! Your changes have been saved.',
  th: 'บันทึกสำเร็จ! ข้อมูลของคุณถูกบันทึกแล้ว',
}

export const DELETE_SUCCESS_MESSAGE = {
  en: 'Success! The item has been deleted.',
  th: 'ลบสำเร็จ! รายการที่เลือกถูกลบแล้ว',
}

export const ERROR_MESSAGE = {
  en: 'Error! An error has occurred. Please try again later.',
  th: 'ผิดพลาด! เกิดข้อผิดพลาด กรุณาลองอีกครั้งในภายหลัง',
}

export const EDIT = { en: 'Edit', th: 'แก้ไข' }

export const DELETE = { en: 'Delete', th: 'ลบ' }

export const SAVE = { en: 'Save', th: 'บันทึก' }

export const PREVIEW = { en: 'Preview', th: 'ดูตัวอย่าง' }

export const CONFIRM = { en: 'Confirm', th: 'ยืนยัน' }

export const CANCEL = { en: 'Cancel', th: 'ยกเลิก' }

export const UPLOAD = { en: 'Upload', th: 'อัพโหลด' }

export const SHARE = { en: 'Share', th: 'Share' }

export const REPORT = { en: 'Report', th: 'Report' }

export const APPLY = { en: 'Apply', th: 'Apply' }

export const NO_DATA = { en: 'No data', th: 'ไม่มีข้อมูล' }

export const SEE_MORE = { en: 'See more', th: 'ดูมากขึ้น' }

export const SEE_LESS = { en: 'See less', th: 'ดูน้อยลง' }

export const MAPS = { en: 'Maps', th: 'แผนที่' }

export const LOCATION = { en: 'Location', th: 'สถานที่' }

export const SEE_DETAILS = { en: 'See details', th: 'ดูรายละเอียด' }

export const OR = { en: 'Or', th: 'หรือ' }

export const SUGGESTION = { en: 'Suggestion', th: 'แนะนำ' }

export const ACTION = { en: 'Action', th: 'เพิ่มเติม' }

export const MONTH = { en: 'Month', th: 'เดือน' }

export const YEAR = { en: 'Year', th: 'ปี' }

export const PRICE_PER_MONTH = { en: '{price} / month', th: '{price} / เดือน' }

export const N_DAYS = {
  en: '{n} {n, plural, =1 {day} other {days}}',
  th: '{n} วัน',
}

export const N_VIEWS = {
  en: '{n} {n, plural, =1 {view} other {views}}',
  th: '{n} จำนวนการดู',
}

export const DELETE_MODAL_TITLE = { en: 'Confirm Delete', th: 'ยืนยันการลบ' }

export const DELETE_MODAL_DESCRIPTION = {
  en: 'Are you sure you want to delete this item?',
  th: 'คุณแน่ใจหรือไม่ว่าต้องการลบรายการนี้',
}

export const DIRECTIONS = {
  N: { en: 'North', th: 'ทิศเหนือ' },
  NE: { en: 'North East', th: 'ทิศตะวันออกเฉียงเหนือ' },
  E: { en: 'East', th: 'ทิศตะวันออก' },
  SE: { en: 'South East', th: 'ทิศตะวันออกเฉียงใต้' },
  S: { en: 'South', th: 'ทิศใต้' },
  SW: { en: 'South West', th: 'ทิศตะวันตกเฉียงใต้' },
  W: { en: 'West', th: 'ทิศตะวันตก' },
  NW: { en: 'North West', th: 'ทิศตะวันตกเฉียงเหนือ' },
}

export const OPTIONAL = { en: 'Optional', th: 'ถ้ามี' }

export const APPROVE = { en: 'Approve', th: 'อนุมัติ' }

export const REJECT = { en: 'Reject', th: 'ปฏิเสธ' }

export const DOCUMENT = { en: 'Document', th: 'เอกสาร' }

export const LATEST_UPDATE = { en: 'Latest update', th: 'อัพเดทล่าสุด' }

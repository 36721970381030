import mapValues from 'lodash/mapValues'
import snakecaseKeys from 'snakecase-keys'

import { SelectOption } from '@/common/components/SelectField'
import { ApiClient, ApiRequest } from '@/common/types'

class SearchApis {
  public getSearchParamOptions: ApiRequest<{
    objectives: SelectOption<string>[]
    commutes: SelectOption<string>[]
    priorities: SelectOption<number>[]
  }>
  public searchByName: ApiRequest<RentAPerfect.Condo[], { keyword?: string }>
  public searchByArea: ApiRequest<
    {
      results: RentAPerfect.SearchedCondo[]
      workplace: RentAPerfect.GooglePlace
    },
    {
      objective: string
      googlePlaceId: string
      budgetMin: number
      budgetMax: number
      bedroom: number
      commuteBy: string
    }
  >
  public advanceSearch: ApiRequest<
    {
      results: RentAPerfect.SearchedCondo[]
      workplace: RentAPerfect.GooglePlace
      preferArea: Nullable<RentAPerfect.GooglePlace>
    },
    {
      objective: string
      googlePlaceId: string
      preferGooglePlaceId?: string
      budgetMin: number
      budgetMax: number
      commuteBy: string
      travelBy: string
      arriveTime: string
      leaveTime: string
      bedroom: number
      occupant: number
      firstPriority: number
      secondPriority: number
      thirdPriority: number
    }
  >

  constructor({ createRequest }: ApiClient) {
    this.getSearchParamOptions = createRequest({
      method: 'get',
      url: () => '/search/advance-param',
      getResponseData: response =>
        mapValues(response.data, param =>
          param.map(option => ({
            value: option.value,
            label: { en: option.EN, th: option.TH },
          }))
        ) as {
          objectives: SelectOption<string>[]
          commutes: SelectOption<string>[]
          priorities: SelectOption<number>[]
        },
    })

    this.searchByName = createRequest({
      method: 'get',
      url: () => '/search/name',
      payload: ({ keyword = '' }) => ({ name: keyword }),
      getResponseData: response => response.data.condos,
    })

    this.searchByArea = createRequest({
      method: 'get',
      url: () => '/search/area',
      payload: props =>
        snakecaseKeys({
          objective: props.objective,
          placeId: props.googlePlaceId,
          budgetMin: props.budgetMin,
          budgetMax: props.budgetMax,
          bedroom: props.bedroom,
          commuteBy: props.commuteBy,
        }),
      getResponseData: response => ({
        results: response.data.condos,
        workplace: response.data.workPlace,
      }),
    })

    this.advanceSearch = createRequest({
      method: 'get',
      url: () => '/search/advance',
      payload: props =>
        snakecaseKeys({
          objective: props.objective,
          placeId: props.googlePlaceId,
          preferPlaceId: props.preferGooglePlaceId,
          budgetMin: props.budgetMin,
          budgetMax: props.budgetMax,
          commuteBy: props.commuteBy,
          travelBy: props.travelBy,
          arriveTime: props.arriveTime,
          leaveTime: props.leaveTime,
          bedroom: props.bedroom,
          occupant: props.occupant,
          firstPriority: props.firstPriority,
          secondPriority: props.secondPriority,
          thirdPriority: props.thirdPriority,
        }),
      getResponseData: response => ({
        results: response.data.results ?? response.data.result,
        workplace: response.data.workPlace,
        preferArea: response.data.preferPlace,
      }),
    })
  }
}

export default SearchApis

import get from 'lodash/get'
import isNil from 'lodash/isNil'
import React, { ReactNode } from 'react'

import { useForm } from '@/common/components/Form'
import Helper from '@/common/components/Helper'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import { OPTIONAL } from '@/common/translation'

import { InfoMessage, ErrorMessage } from './styled'

export interface FieldWrapperProps {
  className?: string
  children?: ReactNode
  name?: string
  value?: any
  action?: ReactNode
  label?: Translation
  optional?: boolean
  helper?: Translation
  info?: Translation
  error?: Translation
  showError?: boolean
  renderValue?: (value: any) => ReactNode
}

const FieldWrapper = ({
  children,
  name,
  value: _value,
  action,
  label,
  optional,
  helper,
  info,
  error: _error,
  showError = true,
  renderValue: _renderValue = value => value,
  ...props
}: FieldWrapperProps) => {
  const { t } = useI18n()
  const { formState, editable = true, getValues } = useForm()

  const error =
    _error ?? (name ? get(formState, `errors.${name}.message`) : undefined)

  const renderChildren = () =>
    typeof children === 'function' ? children({ isError: !!error }) : children

  const renderValue = () => {
    const value = _value ?? (name ? getValues?.()?.[name] : undefined)
    return (!isNil(value) && _renderValue(value)) || '-'
  }

  return (
    <div {...props}>
      {label || (action && editable) ? (
        <div className='flex align-center mb-8'>
          {label ? (
            <div className='flex align-center'>
              <div
                className={
                  editable
                    ? 'text-14 text-semibold'
                    : 'text-gray text-12 text-medium'
                }
              >
                {t(label)}
              </div>
              {optional && editable ? (
                <div className='text-gray text-14 text-medium ml-8'>
                  ({t(OPTIONAL)})
                </div>
              ) : null}
              {helper && editable ? (
                <Helper className='ml-4' message={helper} />
              ) : null}
            </div>
          ) : null}
          {action && editable ? <div className='ml-auto'>{action}</div> : null}
        </div>
      ) : null}
      {editable ? (
        renderChildren()
      ) : (
        <div className='text-14 text-semibold'>{renderValue()}</div>
      )}
      {error && showError ? (
        <ErrorMessage>{t(error)}</ErrorMessage>
      ) : info ? (
        <InfoMessage>{t(info)}</InfoMessage>
      ) : null}
    </div>
  )
}

export default FieldWrapper

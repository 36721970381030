import loadable from '@loadable/component'
import React from 'react'

import { BROKER_REGISTER_PATH, BROKER_VERIFY_PATH } from './paths'

const BrokerRegisterPage = loadable(() => import('./pages/BrokerRegisterPage'))
const BrokerVerifyPage = loadable(() => import('./pages/BrokerVerifyPage'))

export default [
  { path: BROKER_REGISTER_PATH, element: <BrokerRegisterPage /> },
  { path: BROKER_VERIFY_PATH, element: <BrokerVerifyPage /> },
]

import { serialize as objectToFormData } from 'object-to-formdata'

import { REPORT_PROPERTY_REASON_OPTIONS } from '@/common/enums'
import { ApiClient, ApiRequest } from '@/common/types'

class UserApis {
  public getCurrentUser: ApiRequest<RentAPerfect.User>
  public updateProfile: ApiRequest<
    RentAPerfect.User,
    {
      profileImage?: File | null
      firstName?: string
      lastName?: string
      email?: string | null
      phone?: string | null
      birthDate?: string | null
      sex?: string | null
      nationality?: string | null
      language?: string | null
      lineId?: string | null
    }
  >
  public updateLineConnect: ApiRequest<RentAPerfect.User>
  public getFavoriteRooms: ApiRequest<
    RentAPerfect.Room[],
    {
      keyword?: string
      roomStatus?: string
      price?: [number, number]
      rating?: [number, number]
      contractMonths?: [number, number]
      dateAvailable?: string
    }
  >
  public addFavoriteRoom: ApiRequest<RentAPerfect.Room, { roomId: string }>
  public removeFavoriteRoom: ApiRequest<void, { roomId: string }>
  public reportRoom: ApiRequest<
    void,
    {
      roomId: string
      name: string
      email: string
      phoneNumber: string
      factor: string
      text?: string
    }
  >
  public getRentals: ApiRequest<
    RentAPerfect.UserContract[],
    { keyword?: string }
  >
  public getRentalRoom: ApiRequest<RentAPerfect.Room, { contractId: number }>
  public requestContractExtension: ApiRequest<
    RentAPerfect.UserContract[],
    {
      contractId: number
      date: string
    }
  >
  public uploadPaymentSlip: ApiRequest<
    RentAPerfect.Payment,
    {
      contractId: number
      file: File
      month: number
      year: number
    }
  >
  public getAdvancedSearchHistory: ApiRequest<
    RentAPerfect.AdvancedSearchHistory[],
    {
      keyword?: string
      budget?: [number, number]
      bedroom?: number
    }
  >

  constructor({ createRequest }: ApiClient) {
    this.getCurrentUser = createRequest({
      method: 'get',
      url: () => '/user',
    })

    this.updateProfile = createRequest({
      method: 'patch',
      isFormData: true,
      url: () => '/user',
      payload: ({ profileImage, ...props }) => {
        const formData = objectToFormData(props)
        if (profileImage) formData.append('files', profileImage)
        return formData
      },
      getResponseData: response => response.data.updateUser,
    })

    this.updateLineConnect = createRequest({
      method: 'patch',
      url: () => '/user/line',
      getResponseData: response => response.data.updatedUser,
    })

    this.getFavoriteRooms = createRequest({
      method: 'get',
      url: () => '/user/rooms',
      payload: ({ keyword, price, rating, contractMonths, ...props }) => ({
        ...props,
        placeDetail: keyword,
        minPrice: price?.[0],
        maxPrice: price?.[1],
        minRating: rating?.[0],
        maxRating: rating?.[1],
        minRentDuration: contractMonths?.[0],
        maxRentDuration: contractMonths?.[1],
      }),
      getResponseData: response => response.data[0]?.Rooms ?? [],
    })

    this.addFavoriteRoom = createRequest({
      method: 'post',
      url: ({ roomId }) => `/user/rooms/${roomId}`,
      getResponseData: response => ({
        ...response.data.room,
        otherRooms: response.data.otherRooms,
      }),
    })

    this.removeFavoriteRoom = createRequest({
      method: 'delete',
      url: ({ roomId }) => `/user/rooms/${roomId}`,
    })

    this.reportRoom = createRequest({
      method: 'post',
      url: ({ roomId }) => `user/rooms/${roomId}/report`,
      payload: ({ roomId, factor, ...props }) => ({
        ...props,
        ...REPORT_PROPERTY_REASON_OPTIONS.reduce(
          (obj, { value }) => ({ ...obj, [value]: factor === value }),
          {}
        ),
      }),
    })

    this.getRentals = createRequest({
      method: 'get',
      url: () => '/user/rent',
      payload: ({ keyword }) => ({ placeDetail: keyword }),
      getResponseData: response => response.data.rents,
    })

    this.getRentalRoom = createRequest({
      method: 'get',
      url: ({ contractId }) => `/user/rent/${contractId}/payment`,
      getResponseData: response => response.data.room,
    })

    this.requestContractExtension = createRequest({
      method: 'patch',
      url: ({ contractId }) => `/user/contract/${contractId}/extension`,
      payload: ({ date }) => ({ extensionEnd: date }),
      getResponseData: response => response.data.contracts,
    })

    this.uploadPaymentSlip = createRequest({
      method: 'post',
      isFormData: true,
      url: () => '/user/rent/payment',
      payload: ({ file, ...props }) => {
        const formData = objectToFormData(props)
        formData.append('privateFiles', file)
        return formData
      },
      getResponseData: response => response.data.payment,
    })

    this.getAdvancedSearchHistory = createRequest({
      method: 'get',
      url: () => '/user/advance-history',
      payload: ({ keyword, budget, ...props }) => ({
        ...props,
        workPlace: keyword,
        minBudget: budget?.[0],
        maxBudget: budget?.[1],
      }),
    })
  }
}

export default UserApis

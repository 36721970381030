import { AxiosError } from 'axios'
import React, { ReactNode, createContext, useContext, useEffect } from 'react'
import toast from 'react-hot-toast'

import Apis from '@/common/apis'
import useI18n from '@/common/hooks/useI18n'
import { ERROR_MESSAGE } from '@/common/translation'

interface ApisContextValue {
  apis: Apis
}

interface ApisProviderProps {
  children: ReactNode
  apis: Apis
}

const ApisContext = createContext<ApisContextValue | null>(null)

const useApis = () => {
  const value = useContext(ApisContext)

  if (!value) {
    throw new Error('useApis must be used within a ApisProvider')
  }

  return value
}

export const ApisProvider = ({ apis, ...props }: ApisProviderProps) => {
  const { t } = useI18n()

  useEffect(() => {
    apis?.onErrorStateChange((error: AxiosError | null) => {
      if (!error) return

      const data = error.response?.data
      toast.error(
        [data?.error ?? data?.message ?? t(ERROR_MESSAGE)].flat().join(', ')
      )
    })
  }, [t, apis])

  return <ApisContext.Provider {...props} value={{ apis }} />
}

export default useApis

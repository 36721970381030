import React, { useState, useMemo } from 'react'

import ProfileImage from '@/common/components/ProfileImage'
import Separator from '@/common/components/Separator'
import useI18n from '@/common/hooks/useI18n'
import usePermission from '@/common/hooks/usePermission'
import useAuthentication from '@/modules/authentication/hooks/useAuthentication'
import useCurrentUser from '@/modules/authentication/hooks/useCurrentUser'
import { SIGN_OUT } from '@/modules/authentication/translation'
import {
  ME_PATH,
  ME_OWNER_LISTING_CREATE_PATH,
  ME_OWNER_LISTINGS_PATH,
  ME_BROKER_LISTINGS_PATH,
} from '@/modules/me/paths'
import {
  MY_PROFILE,
  CREATE_LISTING,
  OWNER_LISTINGS,
  BROKER_LISTINGS,
} from '@/modules/me/translation'

import { Wrapper, StyledDropDown, MenuItem } from './styled'

const MyProfile = () => {
  const { t } = useI18n()
  const [visible, setVisible] = useState(false)
  const { signOut } = useAuthentication()
  const { data: currentUser } = useCurrentUser()
  const { isOwner, isBroker } = usePermission()

  const menuList = useMemo(
    () => [
      { path: ME_PATH, label: MY_PROFILE },
      ...(isOwner
        ? [
            { path: ME_OWNER_LISTING_CREATE_PATH, label: CREATE_LISTING },
            { path: ME_OWNER_LISTINGS_PATH, label: OWNER_LISTINGS },
          ]
        : []),
      ...(isBroker
        ? [{ path: ME_BROKER_LISTINGS_PATH, label: BROKER_LISTINGS }]
        : []),
    ],
    [isOwner, isBroker]
  )

  if (!currentUser) return null

  return (
    <StyledDropDown
      component={
        <Wrapper>
          <div className='text-14 text-medium flex-1 ellipsis-1'>
            {currentUser.firstName} {currentUser.lastName}
          </div>
          <ProfileImage user={currentUser} size={24} />
        </Wrapper>
      }
      alignment='right'
      visible={visible}
      onVisibleChange={setVisible}
    >
      <div className='py-8'>
        {menuList.map(({ path, label }, index) => (
          <MenuItem
            key={`me-menu-${index}`}
            to={path}
            onClick={() => setVisible(false)}
          >
            {t(label)}
          </MenuItem>
        ))}
        {menuList.length > 1 ? <Separator className='mx-20 my-8' /> : null}
        <MenuItem
          as='div'
          onClick={() => {
            signOut()
            setVisible(false)
          }}
        >
          {t(SIGN_OUT)}
        </MenuItem>
      </div>
    </StyledDropDown>
  )
}

export default MyProfile

import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #222222;
`

export const CopyrightText = styled.div.attrs({
  className: 'text-12',
})`
  color: #7b7b7b;
`

export const Item = styled.div.attrs({
  className: 'flex align-center text-white text-12 text-medium',
})`
  height: 24px;

  &:not(:last-child) {
    border-right: 1px solid #434343;
    padding-right: 20px;
  }
`

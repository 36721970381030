import React, { useRef, HTMLAttributes } from 'react'

import 'rc-tooltip/assets/bootstrap.css'

import HelpIcon from '@/common/components/icons/HelpIcon'
import useDevice from '@/common/hooks/useDevice'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import { isSSR } from '@/common/utils/server'

import { StyledTooltip } from './styled'

interface HelperProps extends HTMLAttributes<SVGAElement> {
  message: Translation
}

const Helper = ({ message, ...props }: HelperProps) => {
  const ref = useRef(null)
  const { t } = useI18n()
  const { isDesktop } = useDevice()

  const modalElement = isSSR()
    ? null
    : (document?.getElementsByClassName('rc-dialog-body')?.[0] as HTMLElement)
  const isInModal = modalElement?.contains(ref.current)

  return (
    <StyledTooltip
      overlay={t(message)}
      placement='top'
      trigger={isDesktop ? 'hover' : 'click'}
      zIndex={isInModal ? 1070 : 1}
      getTooltipContainer={isInModal ? () => modalElement! : undefined}
    >
      <div ref={ref} className='flex'>
        <HelpIcon {...props} size={12} />
      </div>
    </StyledTooltip>
  )
}

export default Helper

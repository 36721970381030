import imageCompression from 'browser-image-compression'

export interface CompressFileOptions {
  maxSizeMB?: number
}

export const compressFile = async (
  file: File,
  { maxSizeMB = 10 }: CompressFileOptions = {}
): Promise<File> => {
  if (file.type !== 'image/jpeg' && file.type !== 'image/png') return file

  const blob = await imageCompression(file, { maxSizeMB })
  return new File([blob], file.name, { type: file.type })
}

import { yupResolver } from '@hookform/resolvers/yup'
import React, {
  createContext,
  useContext,
  ReactNode,
  FormHTMLAttributes,
} from 'react'
import {
  useForm as useHooksForm,
  UseFormReturn as UseHooksFormReturn,
} from 'react-hook-form'
import { AnyObjectSchema } from 'yup'

interface FormContextValue extends UseHooksFormReturn {
  editable: boolean
}

interface FormProps
  extends Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  chilren?: ReactNode | ((form: FormContextValue) => ReactNode)
  defaultValues?: Record<string, any>
  schema?: AnyObjectSchema
  info?: boolean
  onSubmit?: (
    data: any,
    hooksForm: Omit<UseHooksFormReturn, 'handleSubmit'>
  ) => void
}

const FormContext = createContext<FormContextValue | null>(null)

const Form = ({
  children,
  defaultValues,
  schema,
  info = false,
  onSubmit: _onSubmit,
  ...props
}: FormProps) => {
  const { handleSubmit, ...hooksForm } = useHooksForm({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  })

  const renderChildren = () =>
    typeof children === 'function'
      ? children({ ...hooksForm, handleSubmit })
      : children

  const onSubmit = handleSubmit(async data => {
    await _onSubmit?.(data, hooksForm)
  })

  return (
    <FormContext.Provider
      value={{ ...hooksForm, editable: !info, handleSubmit }}
    >
      {info ? (
        renderChildren()
      ) : (
        <form {...props} onSubmit={onSubmit} noValidate>
          {renderChildren()}
        </form>
      )}
    </FormContext.Provider>
  )
}

export const useForm = (): Partial<FormContextValue> => {
  return useContext(FormContext) ?? {}
}

export default Form

import cn from 'classnames'
import React from 'react'
import { Toaster, ToasterProps } from 'react-hot-toast'
import styled from 'styled-components'

const Component = ({
  className,
  ...rest
}: ToasterProps & { className?: string }) => (
  <Toaster
    {...rest}
    containerClassName={cn(className, 'text-14 text-medium')}
  />
)

export const StyledToaster = styled(Component)<ToasterProps>`
  & > div > div {
    font-family: 'Quicksand', 'Sarabun', sans-serif;
    max-width: 100%;
  }
`

import camelcaseKeys from 'camelcase-keys'
import h2p from 'html2plaintext'

import { ApiClient, ApiRequest } from '@/common/types'

const BASE_URL = `${process.env.RAZZLE_ARTICLE_URL}/index.php?rest_route=/wp/v2`

const normalizeData = ({ yoast_head, ...data }) => {
  return camelcaseKeys(data, { deep: true })
}

class ArticleApis {
  public getArticleCategories: ApiRequest<
    RentAPerfect.ArticleCategory[],
    { orderby?: string; order?: string }
  >
  public getArticles: ApiRequest<
    RentAPerfect.Article[],
    { categoryId?: number }
  >
  public getArticleMedia: ApiRequest<
    RentAPerfect.ArticleMedia,
    { mediaId: number }
  >

  constructor({ createRequest }: ApiClient) {
    this.getArticleCategories = createRequest({
      method: 'get',
      baseURL: BASE_URL,
      url: () => '/categories',
      payload: ({ orderby = 'id', order = 'asc' }) => ({ orderby, order }),
      getResponseData: response => response.data.map(normalizeData),
    })

    this.getArticles = createRequest({
      method: 'get',
      baseURL: BASE_URL,
      url: () => '/posts',
      payload: ({ categoryId }) => ({ categories: categoryId }),
      getResponseData: response =>
        response.data.map(normalizeData).map(raw => ({
          id: raw.id,
          slug: raw.slug,
          url: raw.link,
          date: raw.date,
          title: h2p(raw.title.rendered),
          content: h2p(raw.content.rendered),
          mediaId: raw.featuredMedia,
          categories: raw.categories,
        })),
    })

    this.getArticleMedia = createRequest({
      method: 'get',
      baseURL: BASE_URL,
      url: ({ mediaId }) => `/media/${mediaId}`,
      getResponseData: response => ({
        id: response.data.id,
        url: response.data.media_details.sizes.full.source_url,
      }),
    })
  }
}

export default ArticleApis

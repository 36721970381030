import styled from 'styled-components'

interface WrapperProps {
  $size: number
  $bgColor: string
}

interface FirstLetterProps {
  $size: number
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const FirstLetter = styled.div.attrs({
  className: 'text-white text-medium',
})<FirstLetterProps>`
  font-size: ${({ $size }) => $size / 2}px;
`

export const EditableWrapper = styled.div.attrs({
  className: 'text-12 text-white text-medium',
})`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const StyledInput = styled.input.attrs({
  className: 'clickable',
})`
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`

import React from 'react'

import { IconProps } from './types'

const LineIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height={size}
      width={size}
      {...props}
    >
      <path
        fill='currentColor'
        d='M12-3178a11.921 11.921 0 0 1-8.485-3.515A11.921 11.921 0 0 1 0-3190a11.923 11.923 0 0 1 3.515-8.486A11.921 11.921 0 0 1 12-3202a11.923 11.923 0 0 1 8.486 3.515A11.922 11.922 0 0 1 24-3190a11.921 11.921 0 0 1-3.515 8.485A11.923 11.923 0 0 1 12-3178zm-.085-19.109c-4.506 0-8.171 2.995-8.171 6.675 0 3.285 2.874 6.054 6.833 6.583.228.049.623.166.72.4a1.592 1.592 0 0 1 .026.741l-.112.7v.031a.584.584 0 0 0 .048.444.274.274 0 0 0 .219.084 1.233 1.233 0 0 0 .453-.117 26.6 26.6 0 0 0 6.425-4.779 5.952 5.952 0 0 0 1.735-4.09c-.005-3.677-3.67-6.672-8.176-6.672zm5.015 8.864h-1.625a.43.43 0 0 1-.427-.431v-3.269a.431.431 0 0 1 .429-.432h1.624a.43.43 0 0 1 .428.432.431.431 0 0 1-.429.431h-1.2v.771h1.2a.431.431 0 0 1 .429.432.431.431 0 0 1-.429.432h-1.2v.771h1.2a.431.431 0 0 1 .429.432.431.431 0 0 1-.429.431zm-6.964 0a.429.429 0 0 1-.427-.431v-3.269a.431.431 0 0 1 .429-.432.43.43 0 0 1 .427.432v3.269a.431.431 0 0 1-.429.431zm-1.249 0H7.092a.431.431 0 0 1-.429-.431v-3.269a.431.431 0 0 1 .429-.432.431.431 0 0 1 .428.432v2.837h1.2a.431.431 0 0 1 .428.432.43.43 0 0 1-.431.431zm2.718 0a.429.429 0 0 1-.426-.431v-3.268a.431.431 0 0 1 .293-.409.406.406 0 0 1 .132-.022.442.442 0 0 1 .337.175l1.677 2.281v-2.025a.431.431 0 0 1 .429-.432.431.431 0 0 1 .428.432v3.269a.432.432 0 0 1-.294.409.432.432 0 0 1-.135.021.42.42 0 0 1-.347-.171l-1.663-2.273v2.014a.431.431 0 0 1-.431.429z'
        transform='translate(0 3202)'
      />
    </svg>
  )
}

export default LineIcon

import loadable from '@loadable/component'
import React from 'react'

import {
  ME_PATH,
  ME_PERSONAL_INFORMATION_PATH,
  ME_PERSONAL_INFORMATION_EDIT_PATH,
  ME_RENTAL_HISTORY_PATH,
  ME_RENTAL_HISTORY_PAYMENTS_PATH,
  ME_SAVED_LISTINGS_PATH,
  ME_ADVANCED_SEARCH_HISTORY_PATH,
  ME_BROKER_PROFILE_PATH,
  ME_BROKER_PROFILE_EDIT_PATH,
  ME_BROKER_LISTINGS_PATH,
  ME_BROKER_LISTING_DEALS_PATH,
  ME_OWNER_PROFILE_PATH,
  ME_OWNER_PROFILE_EDIT_PATH,
  ME_OWNER_LISTINGS_PATH,
  ME_OWNER_LISTING_CREATE_PATH,
  ME_OWNER_LISTING_EDIT_PATH,
  ME_OWNER_LISTING_RENTAL_HISTORY_PATH,
  ME_OWNER_LISTING_RENTAL_PAYMENTS_PATH,
  ME_OWNER_LISTING_BORKER_LIST_PATH,
} from './paths'

const MeMenuPage = loadable(() => import('./pages/MeMenuPage'))
const PersonalInformationPage = loadable(
  () => import('./pages/PersonalInformationPage')
)
const RentalHistoryPage = loadable(() => import('./pages/RentalHistoryPage'))
const RentalHistoryPaymentsPage = loadable(
  () => import('./pages/RentalHistoryPaymentsPage')
)
const SavedListingsPage = loadable(() => import('./pages/SavedListingsPage'))
const AdvancedSearchHistoryPage = loadable(
  () => import('./pages/AdvancedSearchHistoryPage')
)
const BrokerProfilePage = loadable(() => import('./pages/BrokerProfilePage'))
const BrokerListingsPage = loadable(() => import('./pages/BrokerListingsPage'))
const BrokerListingDealsPage = loadable(
  () => import('./pages/BrokerListingDealsPage')
)
const OwnerProfilePage = loadable(() => import('./pages/OwnerProfilePage'))
const OwnerListingsPage = loadable(() => import('./pages/OwnerListingsPage'))
const OwnerListingCreatePage = loadable(
  () => import('./pages/OwnerListingCreatePage')
)
const OwnerListingEditPage = loadable(
  () => import('./pages/OwnerListingEditPage')
)
const OwnerListingRentalHistoryPage = loadable(
  () => import('./pages/OwnerListingRentalHistoryPage')
)
const OwnerListingRentalPaymentsPage = loadable(
  () => import('./pages/OwnerListingRentalPaymentsPage')
)
const OwnerListingBrokerListPage = loadable(
  () => import('./pages/OwnerListingBrokerListPage')
)

export default [
  {
    path: ME_PATH,
    element: <MeMenuPage />,
    routes: [
      {
        path: ME_PERSONAL_INFORMATION_PATH,
        element: <PersonalInformationPage />,
      },
      {
        path: ME_PERSONAL_INFORMATION_EDIT_PATH,
        element: <PersonalInformationPage editable />,
      },
      {
        path: ME_RENTAL_HISTORY_PATH,
        element: <RentalHistoryPage />,
      },
      {
        path: ME_RENTAL_HISTORY_PAYMENTS_PATH,
        element: <RentalHistoryPaymentsPage />,
      },
      {
        path: ME_SAVED_LISTINGS_PATH,
        element: <SavedListingsPage />,
      },
      {
        path: ME_ADVANCED_SEARCH_HISTORY_PATH,
        element: <AdvancedSearchHistoryPage />,
      },
      {
        path: ME_BROKER_PROFILE_PATH,
        element: <BrokerProfilePage />,
      },
      {
        path: ME_BROKER_PROFILE_EDIT_PATH,
        element: <BrokerProfilePage editable />,
      },
      {
        path: ME_BROKER_LISTINGS_PATH,
        element: <BrokerListingsPage />,
      },
      {
        path: ME_BROKER_LISTING_DEALS_PATH,
        element: <BrokerListingDealsPage />,
      },
      {
        path: ME_OWNER_PROFILE_PATH,
        element: <OwnerProfilePage />,
      },
      {
        path: ME_OWNER_PROFILE_EDIT_PATH,
        element: <OwnerProfilePage editable />,
      },
      {
        path: ME_OWNER_LISTINGS_PATH,
        element: <OwnerListingsPage />,
      },
      {
        path: ME_OWNER_LISTING_CREATE_PATH,
        element: <OwnerListingCreatePage />,
      },
      {
        path: ME_OWNER_LISTING_EDIT_PATH,
        element: <OwnerListingEditPage />,
      },
      {
        path: ME_OWNER_LISTING_RENTAL_HISTORY_PATH,
        element: <OwnerListingRentalHistoryPage />,
      },
      {
        path: ME_OWNER_LISTING_RENTAL_PAYMENTS_PATH,
        element: <OwnerListingRentalPaymentsPage />,
      },
      {
        path: ME_OWNER_LISTING_BORKER_LIST_PATH,
        element: <OwnerListingBrokerListPage />,
      },
    ],
  },
]

import loadable from '@loadable/component'
import React from 'react'

import { OWNER_REGISTER_PATH, OWNER_VERIFY_PATH } from './paths'

const OwnerRegisterPage = loadable(() => import('./pages/OwnerRegisterPage'))
const OwnerVerifyPage = loadable(() => import('./pages/OwnerVerifyPage'))

export default [
  { path: OWNER_REGISTER_PATH, element: <OwnerRegisterPage /> },
  { path: OWNER_VERIFY_PATH, element: <OwnerVerifyPage /> },
]

import MobileDetect from 'mobile-detect'
import { useState, useLayoutEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from 'styled-components'

import useUserAgent from '@/common/hooks/useUserAgent'

const useDevice = () => {
  const { breakpoints } = useTheme()
  const { data: userAgent } = useUserAgent()
  const [isMounted, setIsMounted] = useState(false)

  const mobileDetect = userAgent ? new MobileDetect(userAgent) : null
  const isMobileSSR =
    mobileDetect?.phone() !== null || mobileDetect?.mobile() === 'UnknownMobile'
  const isTabletSSR =
    mobileDetect?.tablet() !== null ||
    mobileDetect?.mobile() === 'UnknownTablet'
  const isDesktopSSR = !isMobileSSR && !isTabletSSR

  const isMobileCSR = useMediaQuery({
    maxWidth: breakpoints.sm - 1,
  })
  const isTabletCSR = useMediaQuery({
    minWidth: breakpoints.sm,
    maxWidth: breakpoints.lg - 1,
  })
  const isDesktopCSR = useMediaQuery({
    minWidth: breakpoints.lg,
  })

  useLayoutEffect(() => {
    setIsMounted(true)
  }, [])

  return {
    isMobile: isMounted ? isMobileCSR : isMobileSSR,
    isTablet: isMounted ? isTabletCSR : isTabletSSR,
    isDesktop: isMounted ? isDesktopCSR : isDesktopSSR,
  }
}

export default useDevice

import React, { ReactNode, createContext, useContext } from 'react'

import LoadingOverlay from '@/common/components/LoadingOverlay'
import useSwitch from '@/common/hooks/useSwitch'

interface LoadingOverlayContextValue {
  isLoading: boolean
  open: () => void
  close: () => void
}

interface LoadingOverlayProviderProps {
  children: ReactNode
}

const LoadingOverlayContext = createContext<LoadingOverlayContextValue | null>(
  null
)

const useLoadingOverlay = () => {
  const value = useContext(LoadingOverlayContext)

  if (!value) {
    throw new Error(
      'useLoadingOverlay must be used within a LoadingOverlayProvider'
    )
  }

  return value
}

export const LoadingOverlayProvider = ({
  children,
}: LoadingOverlayProviderProps) => {
  const { value: isLoading, on: open, off: close } = useSwitch()

  return (
    <LoadingOverlayContext.Provider value={{ isLoading, open, close }}>
      {children}
      <LoadingOverlay isOpen={isLoading} />
    </LoadingOverlayContext.Provider>
  )
}

export default useLoadingOverlay

import { css } from 'styled-components'

const AVAIABLE_FONT_SIZE = [10, 12, 14, 16, 20, 24, 32, 44, 48]

const AVAIABLE_FONT_WEIGHT = [
  { name: 'thin', weight: 300 },
  { name: 'regular', weight: 400 },
  { name: 'medium', weight: 500 },
  { name: 'semibold', weight: 600 },
  { name: 'bold', weight: 700 },
]

const AVAIABLE_ELLIPSIS_LINES = [1, 2]

export default [
  {
    name: 'text-primary',
    value: css`
      color: ${({ theme }) => theme.colors.primary};
    `,
  },
  {
    name: 'text-hover-primary',
    value: css`
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    `,
  },
  {
    name: 'text-secondary',
    value: css`
      color: ${({ theme }) => theme.colors.secondary};
    `,
  },
  {
    name: 'text-hover-secondary',
    value: css`
      &:hover {
        color: ${({ theme }) => theme.colors.secondary};
      }
    `,
  },
  {
    name: 'text-white',
    value: css`
      color: white;
    `,
  },
  {
    name: 'text-hover-white',
    value: css`
      &:hover {
        color: white;
      }
    `,
  },
  {
    name: 'text-black',
    value: css`
      color: black;
    `,
  },
  {
    name: 'text-hover-black',
    value: css`
      &:hover {
        color: black;
      }
    `,
  },
  {
    name: 'text-gray',
    value: css`
      color: #7b7b7b;
    `,
  },
  {
    name: 'text-hover-gray',
    value: css`
      &:hover {
        color: #7b7b7b;
      }
    `,
  },
  {
    name: 'text-success',
    value: css`
      color: ${({ theme }) => theme.colors.success};
    `,
  },
  {
    name: 'text-hover-success',
    value: css`
      &:hover {
        color: ${({ theme }) => theme.colors.success};
      }
    `,
  },
  {
    name: 'text-error',
    value: css`
      color: ${({ theme }) => theme.colors.error};
    `,
  },
  {
    name: 'text-hover-error',
    value: css`
      &:hover {
        color: ${({ theme }) => theme.colors.error};
      }
    `,
  },
  {
    name: 'text-center',
    value: css`
      text-align: center;
    `,
  },
  {
    name: 'text-left',
    value: css`
      text-align: left;
    `,
  },
  {
    name: 'text-right',
    value: css`
      text-align: right;
    `,
  },
  {
    name: 'text-nowrap',
    value: css`
      white-space: nowrap;
    `,
  },
  ...AVAIABLE_FONT_SIZE.map(size => ({
    name: `text-${size}`,
    value: css`
      font-size: ${size}px !important;
    `,
  })),
  ...AVAIABLE_FONT_WEIGHT.map(({ name, weight }) => ({
    name: `text-${name}`,
    value: css`
      font-weight: ${weight} !important;
    `,
  })),
  ...AVAIABLE_ELLIPSIS_LINES.map(line => ({
    name: `ellipsis-${line}`,
    value: css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${line};
      -webkit-box-orient: vertical;
    `,
  })),
]

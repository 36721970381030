import React, { ReactNode, createContext, useContext, useState } from 'react'

type GlobalModal =
  | 'privacy_policy'
  | 'terms_and_conditions'
  | 'register_suggestion'

interface GlobalStateContextValue {
  openingModal: GlobalModal | null
  openModal: (name: GlobalModal) => void
  closeModal: () => void
}

interface GlobalStateProviderProps {
  children: ReactNode
}

const GlobalStateContext = createContext<GlobalStateContextValue | null>(null)

const useGlobalState = () => {
  const value = useContext(GlobalStateContext)

  if (!value) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider')
  }

  return value
}

export const GlobalStateProvider = (props: GlobalStateProviderProps) => {
  const [openingModal, setOpeningModal] = useState<GlobalModal | null>(null)

  const openModal = (name: GlobalModal) => {
    setOpeningModal(name)
  }

  const closeModal = () => {
    setOpeningModal(null)
  }

  return (
    <GlobalStateContext.Provider
      {...props}
      value={{ openingModal, openModal, closeModal }}
    />
  )
}

export default useGlobalState

import { css } from 'styled-components'

const AVAIABLE_BORDER_RADIUS_SIZE = [0, 4, 8]

export default [
  ...AVAIABLE_BORDER_RADIUS_SIZE.map(size => ({
    name: `radius-${size}`,
    value: css`
      border-radius: ${size}px;
    `,
  })),
  {
    name: `radius-round`,
    value: css`
      border-radius: 50%;
    `,
  },
  {
    name: 'border-bottom-primary',
    value: css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    `,
  },
]

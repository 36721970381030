import {
  RATING_PROPERTY_OPTIONS,
  RATING_TENANT_OPTIONS,
  RATING_BROKER_OPTIONS,
} from '@/common/enums'
import { ApiClient, ApiRequest } from '@/common/types'

class ReviewApis {
  public createRoomReview: ApiRequest<
    RentAPerfect.RoomReview,
    {
      roomId: string
      score: number
      factors: string[]
      description?: string
    }
  >
  public updateRoomReview: ApiRequest<
    RentAPerfect.RoomReview,
    {
      roomId: string
      score: number
      factors: string[]
      description?: string
    }
  >
  public createRenterReview: ApiRequest<
    RentAPerfect.RenterReview,
    {
      userId: string
      score: number
      factors: string[]
      description?: string
    }
  >
  public updateRenterReview: ApiRequest<
    RentAPerfect.RenterReview,
    {
      userId: string
      score: number
      factors: string[]
      description?: string
    }
  >
  public createBrokerReview: ApiRequest<
    RentAPerfect.BrokerReview,
    {
      brokerId: string
      score: number
      factors: string[]
      description?: string
    }
  >
  public updateBrokerReview: ApiRequest<
    RentAPerfect.BrokerReview,
    {
      brokerId: string
      score: number
      factors: string[]
      description?: string
    }
  >

  constructor({ createRequest }: ApiClient) {
    this.createRoomReview = createRequest({
      method: 'post',
      url: () => '/review/room',
      payload: ({ factors, description = '', ...props }) => ({
        ...props,
        review: RATING_PROPERTY_OPTIONS.reduce(
          (obj, { value }) => ({
            ...obj,
            [value]:
              value === 'factorOther' ? description : factors.includes(value),
          }),
          {}
        ),
      }),
      getResponseData: response => response.data.roomReview,
    })

    this.updateRoomReview = createRequest({
      method: 'put',
      url: () => '/review/room',
      payload: ({ factors, description = '', ...props }) => ({
        ...props,
        review: RATING_PROPERTY_OPTIONS.reduce(
          (obj, { value }) => ({
            ...obj,
            [value]:
              value === 'factorOther' ? description : factors.includes(value),
          }),
          {}
        ),
      }),
    })

    this.createRenterReview = createRequest({
      method: 'post',
      url: () => '/review/renter',
      payload: ({ factors, description = '', ...props }) => ({
        ...props,
        review: RATING_TENANT_OPTIONS.reduce(
          (obj, { value }) => ({
            ...obj,
            [value]:
              value === 'factorOther' ? description : factors.includes(value),
          }),
          {}
        ),
      }),
      getResponseData: response => response.data.renterReview,
    })

    this.updateRenterReview = createRequest({
      method: 'put',
      url: () => '/review/renter',
      payload: ({ factors, description = '', ...props }) => ({
        ...props,
        review: RATING_TENANT_OPTIONS.reduce(
          (obj, { value }) => ({
            ...obj,
            [value]:
              value === 'factorOther' ? description : factors.includes(value),
          }),
          {}
        ),
      }),
    })

    this.createBrokerReview = createRequest({
      method: 'post',
      url: () => '/review/broker',
      payload: ({ factors, description = '', ...props }) => ({
        ...props,
        review: RATING_BROKER_OPTIONS.reduce(
          (obj, { value }) => ({
            ...obj,
            [value]:
              value === 'factorOther' ? description : factors.includes(value),
          }),
          {}
        ),
      }),
      getResponseData: response => response.data.brokerReview,
    })

    this.updateBrokerReview = createRequest({
      method: 'put',
      url: () => '/review/broker',
      payload: ({ factors, description = '', ...props }) => ({
        ...props,
        review: RATING_BROKER_OPTIONS.reduce(
          (obj, { value }) => ({
            ...obj,
            [value]:
              value === 'factorOther' ? description : factors.includes(value),
          }),
          {}
        ),
      }),
    })
  }
}

export default ReviewApis

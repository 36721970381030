const theme = {
  breakpoints: {
    sm: 768,
    lg: 1024,
  },
  colors: {
    primary: '#894e96',
    secondary: '#c53a30',
    success: '#31b712',
    error: '#c43535',
  },
}

export default theme

import React from 'react'

import { IconProps } from './types'

const HelpIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 11.999 11.999'
      height={size}
      width={size}
      {...props}
    >
      <g>
        <g>
          <g>
            <path
              d='M25.336 23.653a.462.462 0 0 1-.458-.466v-.231a.382.382 0 0 1 0-.04 2.213 2.213 0 0 1 .869-1.416c.122-.112.237-.218.335-.329a.626.626 0 0 0 .11-.744 1.021 1.021 0 0 0-1.122-.4.859.859 0 0 0-.624.654.462.462 0 1 1-.893-.239 1.753 1.753 0 0 1 1.311-1.316 1.923 1.923 0 0 1 2.139.86 1.543 1.543 0 0 1-.23 1.8 5.529 5.529 0 0 1-.4.395c-.332.306-.539.511-.574.807v.209a.462.462 0 0 1-.462.459z'
              transform='translate(-5.999 -6.036) translate(10.044 9.044) translate(-23.537 -19.069)'
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d='M29.782 41.851a.466.466 0 0 1-.326-.134.46.46 0 0 1 0-.654.478.478 0 0 1 .652 0 .458.458 0 0 1 .136.326.458.458 0 0 1-.462.462z'
              transform='translate(-5.999 -6.036) translate(11.38 14.094) translate(-29.32 -40.935)'
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d='M11.982 18.035a5.949 5.949 0 0 1-1.547-.2 6.033 6.033 0 1 1 1.547.2zm.029-11.075a5.077 5.077 0 0 0-1.336 9.98 5.034 5.034 0 0 0 1.307.172 5.08 5.08 0 0 0 2.284-9.624 5.063 5.063 0 0 0-2.255-.527z'
              transform='translate(-5.999 -6.036) translate(5.999 6.036) translate(-5.999 -6.036)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HelpIcon

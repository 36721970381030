import React, {
  ReactNode,
  createContext,
  useReducer,
  useContext,
  useCallback,
  useEffect,
} from 'react'
import { useQueryClient } from 'react-query'

import useApis from '@/common/hooks/useApis'

interface AuthenticationContextValue {
  isAuthenticated: boolean
  signIn: (params: { email: string; password: string }) => Promise<void>
  socialSignIn: (params: { token: string }) => Promise<void>
  signUp: (params: {
    email: string
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
  }) => Promise<void>
  signOut: () => Promise<void>
}

interface AppApisProviderProps {
  children: ReactNode
  isAuthenticated: boolean
}

const AuthenticationContext = createContext<AuthenticationContextValue | null>(
  null
)

function useAuthentication() {
  const value = useContext(AuthenticationContext)

  if (!value) {
    throw new Error(
      'useAuthentication must be used within a AuthenticationProvider'
    )
  }

  return value
}

export function AuthenticationProvider({
  children,
  isAuthenticated: initIsAuthenticated,
}: AppApisProviderProps) {
  const { apis } = useApis()
  const queryClient = useQueryClient()

  const [{ isAuthenticated }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SIGN_IN':
        case 'SIGN_UP': {
          return { ...state, isAuthenticated: true }
        }
        case 'SIGN_OUT': {
          return { ...state, isAuthenticated: false }
        }
        default: {
          return state
        }
      }
    },
    { isAuthenticated: initIsAuthenticated }
  )

  const signIn = useCallback(
    async (params: { email: string; password: string }) => {
      const response = await apis.auth.signIn(params)
      queryClient.clear()
      dispatch({ type: 'SIGN_IN' })
      return response
    },
    [apis.auth, queryClient]
  )

  const socialSignIn = useCallback(
    async (params: { token: string }) => {
      const response = await apis.auth.socialSignIn(params)
      queryClient.clear()
      dispatch({ type: 'SIGN_IN' })
      return response
    },
    [apis.auth, queryClient]
  )

  const signUp = useCallback(
    async (params: {
      email: string
      password: string
      confirmPassword: string
      firstName: string
      lastName: string
    }) => {
      const response = await apis.auth.signUp(params)
      queryClient.clear()
      dispatch({ type: 'SIGN_UP' })
      return response
    },
    [apis.auth, queryClient]
  )

  const signOut = useCallback(async () => {
    if (!isAuthenticated) return

    await apis.auth.signOut()
    queryClient.clear()
    dispatch({ type: 'SIGN_OUT' })
  }, [apis.auth, queryClient, isAuthenticated])

  const value = {
    isAuthenticated,
    signIn,
    socialSignIn,
    signUp,
    signOut,
  }

  useEffect(() => {
    apis.auth.onAuthStateChange((token: string | null) => {
      if (token) return

      queryClient.clear()
      dispatch({ type: 'SIGN_OUT' })
    })
  }, [apis, queryClient])

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export default useAuthentication

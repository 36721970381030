import React from 'react'

import { IconProps } from './types'

const DownloadIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 11.999 11.999'
      height={size}
      width={size}
      {...props}
    >
      <path
        fill='currentColor'
        d='M12 11.249a.75.75 0 0 1-.75.75H.75a.75.75 0 0 1 0-1.5h10.5a.75.75 0 0 1 .75.75zM5.469 8.873a.75.75 0 0 0 1.061 0l2.657-2.657a.75.75 0 0 0-1.061-1.06L6.749 6.532V.75a.75.75 0 0 0-1.5 0v5.782L3.873 5.156a.75.75 0 0 0-1.061 1.06z'
        transform='translate(0 0)'
      />
    </svg>
  )
}

export default DownloadIcon

import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { COOKIE_KEY_ACCESS_TOKEN } from '@/common/apis/auth'
import useApis from '@/common/hooks/useApis'
import { PrefetchRequest } from '@/common/types'
import useAuthentication from '@/modules/authentication/hooks/useAuthentication'

const QUERY_KEY = 'current-user'

const useCurrentUser = () => {
  const { apis } = useApis()
  const { isAuthenticated } = useAuthentication()

  const {
    data = null,
    refetch,
    ...query
  } = useQuery(QUERY_KEY, () => apis.user.getCurrentUser(), {
    enabled: isAuthenticated,
  })

  useEffect(() => {
    isAuthenticated && !data && refetch()
  }, [isAuthenticated, data, refetch])

  return { ...query, data, refetch }
}

export const prefetchCurrentUser = async ({
  apis,
  cookies,
  queryClient,
}: PrefetchRequest) => {
  return queryClient.fetchQuery(QUERY_KEY, async () => {
    if (!cookies[COOKIE_KEY_ACCESS_TOKEN]) return undefined

    try {
      await apis.auth.refreshAccessToken()
      const currentUser = await apis.user.getCurrentUser()
      return currentUser
    } catch (error) {
      return undefined
    }
  })
}

export default useCurrentUser

import React, { ButtonHTMLAttributes } from 'react'

import { StyledButton } from './styled'
import { ButtonVariant, ButtonColor } from './types'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant
  color?: ButtonColor
  fullWidth?: boolean
}

const Button = ({
  variant,
  color = 'primary',
  fullWidth = false,
  ...props
}: ButtonProps) => (
  <StyledButton
    {...props}
    $variant={variant}
    $color={color}
    $fullWidth={fullWidth}
  />
)

export default Button

import React from 'react'

import LoadingIndicator from '@/common/components/LoadingIndicator'

import { Wrapper } from './styled'

interface LoadingOverlayProps {
  isOpen: boolean
}

const LoadingOverlay = ({ isOpen }: LoadingOverlayProps) => {
  return (
    <Wrapper $isOpen={isOpen}>
      <LoadingIndicator color='#ffffff' />
    </Wrapper>
  )
}

export default LoadingOverlay

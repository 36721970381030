import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { PrefetchRequest } from '@/common/types'

const QUERY_KEY = 'user-agent'

const useUserAgent = () => {
  const {
    data = null,
    refetch,
    ...query
  } = useQuery(QUERY_KEY, () => window.navigator.userAgent)

  useEffect(() => {
    refetch()
  }, [refetch])

  return { ...query, data, refetch }
}

export const prefetchUserAgent = async ({
  headers,
  queryClient,
}: PrefetchRequest) => {
  return queryClient.fetchQuery(QUERY_KEY, () => headers['user-agent'])
}

export default useUserAgent

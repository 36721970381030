import { colord } from 'colord'
import React, { ChangeEvent } from 'react'
import stc from 'string-to-color'

import { FILE_ACCEPT } from '@/common/components/FileField'
import Gap from '@/common/components/Gap'
import CameraIcon from '@/common/components/icons/CameraIcon'
import useI18n from '@/common/hooks/useI18n'
import { getFileUrl } from '@/common/utils/url'

import {
  Wrapper,
  Image,
  FirstLetter,
  EditableWrapper,
  StyledInput,
} from './styled'
import { UPLOAD_IMAGE } from './translation'

interface ProfileImageProps {
  user: RentAPerfect.User
  size: number
  editable?: boolean
  editingFile?: File | null
  onEditingFileChange?: (file: File) => void
}

const ProfileImage = ({
  user,
  size,
  editable = false,
  editingFile = null,
  onEditingFileChange,
}: ProfileImageProps) => {
  const { t } = useI18n()

  const { firstName, lastName, profilePhoto } = user
  const imageUrl =
    editable && editingFile
      ? URL.createObjectURL(editingFile)
      : getFileUrl(profilePhoto)!

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.files?.[0]
    value && onEditingFileChange?.(value)
  }

  return (
    <Wrapper
      $size={size}
      $bgColor={
        imageUrl
          ? 'white'
          : colord(stc(`${firstName} ${lastName}`))
              .darken(0.2)
              .toHex()
      }
    >
      {imageUrl ? (
        <Image src={imageUrl} />
      ) : (
        <FirstLetter $size={size}>
          {firstName.charAt(0).toUpperCase()}
        </FirstLetter>
      )}
      {editable ? (
        <EditableWrapper>
          <Gap className='align-center' space={4} vertical>
            <CameraIcon size={14} />
            <div>{t(UPLOAD_IMAGE)}</div>
          </Gap>
          <StyledInput
            type='file'
            accept={FILE_ACCEPT.image}
            onChange={onInputChange}
          />
        </EditableWrapper>
      ) : null}
    </Wrapper>
  )
}

export default ProfileImage

import React from 'react'

import Button, { ButtonProps } from '@/common/components/Button'
import { LinkProps } from '@/common/components/Link'

import { StyledLink } from './styled'

type ButtonLinkProps = LinkProps &
  ButtonProps & {
    buttonClassName?: string
  }

const ButtonLink = ({
  className,
  buttonClassName,
  to,
  external,
  reload,
  ...props
}: ButtonLinkProps) => {
  return (
    <StyledLink
      className={className}
      to={to}
      external={external}
      reload={reload}
    >
      <Button {...props} className={buttonClassName} />
    </StyledLink>
  )
}

export default ButtonLink

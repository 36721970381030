import { css } from 'styled-components'

export const DEFAULT_FONT_WEIGHT = 400

const LATIN_UNICODE_RANGE =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
const THAI_UNICODE_RANGE = 'U+0E01-0E5B, U+200C-200D, U+25CC'

const AVAIABLE_FONT_FACE = [
  {
    name: "'Quicksand'",
    weight: 300,
    url: 'https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Quicksand'",
    weight: 400,
    url: 'https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Quicksand'",
    weight: 500,
    url: 'https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Quicksand'",
    weight: 600,
    url: 'https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Quicksand'",
    weight: 700,
    url: 'https://fonts.gstatic.com/s/quicksand/v24/6xKtdSZaM9iE8KbpRA_hK1QNYuDyPw.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 300,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YNpoik8s6yLUrwB0lw.woff2',
    unicodeRange: THAI_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 300,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YNpoilss6yLUrwA.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 400,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YL5rik8s6yLUrwB0lw.woff2',
    unicodeRange: THAI_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 400,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YL5rilss6yLUrwA.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 500,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVjJx26TKEr37c9aAFJn3YO5gjupg.woff2',
    unicodeRange: THAI_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 500,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVjJx26TKEr37c9aBVJn3YO5gg.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 600,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YOZqik8s6yLUrwB0lw.woff2',
    unicodeRange: THAI_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 600,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YOZqilss6yLUrwA.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 700,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YMptik8s6yLUrwB0lw.woff2',
    unicodeRange: THAI_UNICODE_RANGE,
  },
  {
    name: "'Sarabun'",
    weight: 700,
    url: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YMptilss6yLUrwA.woff2',
    unicodeRange: LATIN_UNICODE_RANGE,
  },
]

export default css`
  ${AVAIABLE_FONT_FACE.map(
    ({ name, weight, url, unicodeRange }) => css`
      @font-face {
        font-family: ${name};
        font-weight: ${weight};
        font-style: normal;
        font-display: swap;
        src: url(${url}) format('woff2');
        unicode-range: ${unicodeRange};
      }
    `
  )}
`

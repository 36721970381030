import {
  css,
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
} from 'styled-components'
import { normalize } from 'styled-normalize'

import { media } from '@/common/utils/viewport'

import borderClasses from './border'
import fontFace, { DEFAULT_FONT_WEIGHT } from './fontFace'
import generalClasses from './general'
import layoutClasses from './layout'
import spaceClasses from './space'
import typographyClasses from './typography'

interface Class {
  name: string
  value: FlattenInterpolation<ThemeProps<DefaultTheme>>
}

const createClasses = (classes: Class[]) => css`
  ${classes.map(
    ({ name, value }) => css`
      .${name} {
        ${value}
      }
    `
  )}

  ${classes.map(
    ({ name, value }) => css`
      ${media.down('lg')`
        .${name}-tablet {
          ${value}
        }
      `}
    `
  )}
  
  ${classes.map(
    ({ name, value }) => css`
      ${media.down('sm')`
        .${name}-mobile {
          ${value}
        }
      `}
    `
  )}
`

export default css`
  ${normalize}
  ${fontFace}

  * {
    box-sizing: border-box;
    outline: none;
  }

  body {
    font-family: 'Quicksand', 'Sarabun', sans-serif;
    font-weight: ${DEFAULT_FONT_WEIGHT};
    line-height: 1.5;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .pac-container,
  .rc-picker-dropdown {
    z-index: 1051 !important;
  }

  .rc-dialog-mask {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  ${createClasses(generalClasses)}
  ${createClasses(layoutClasses)}
  ${createClasses(spaceClasses)}
  ${createClasses(borderClasses)}
  ${createClasses(typographyClasses)}
`

import React from 'react'
import { useLocation } from 'react-router-dom'

import Container from '@/common/components/Container'
import Gap from '@/common/components/Gap'
import Image from '@/common/components/Image'
import Link from '@/common/components/Link'
import useGlobalState from '@/common/hooks/useGlobalState'
import useI18n from '@/common/hooks/useI18n'
import { supportedLangs } from '@/common/i18n'
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '@/common/translation'

import { Wrapper, CopyrightText, Item } from './styled'

const Copyright = () => {
  const { t, language } = useI18n()
  const location = useLocation()
  const { openModal } = useGlobalState()

  const currentLangauge = supportedLangs.find(({ code }) => code === language)!
  const nextLangauge = supportedLangs.find(({ code }) => code !== language)!

  return (
    <Wrapper>
      <Container className='py-16'>
        <div className='flex flex-row-reverse flex-column-mobile align-center justify-space-between'>
          <Gap className='mb-16-mobile' space={16}>
            <Item>
              <Link
                className='flex align-center'
                to={`/${nextLangauge.code}${location.pathname}${location.search}`}
                reload
              >
                <Image
                  src={require(`@/common/images/flag-${currentLangauge.code}.png`)}
                  alt={currentLangauge.name}
                  width={20}
                  height={20}
                />
                <div className='ml-8'>{currentLangauge.name}</div>
              </Link>
            </Item>
            <Item>
              <div
                className='clickable'
                onClick={() => openModal('privacy_policy')}
              >
                {t(PRIVACY_POLICY)}
              </div>
            </Item>
            <Item>
              <div
                className='clickable'
                onClick={() => openModal('terms_and_conditions')}
              >
                {t(TERMS_AND_CONDITIONS)}
              </div>
            </Item>
          </Gap>
          <CopyrightText>Copyright © 2023 Seetap</CopyrightText>
        </div>
      </Container>
    </Wrapper>
  )
}

export default Copyright

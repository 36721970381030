import { colord } from 'colord'
import { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

import { ButtonVariant, ButtonColor } from './types'

interface StyledButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  $variant: ButtonVariant
  $color: ButtonColor
  $fullWidth: boolean
}

const applyVariant = ({ disabled, $variant, $color }: StyledButtonProps) => {
  if (disabled) {
    switch ($variant) {
      case 'outline':
        return css`
          color: #dddddd;
          border: 1px solid #dddddd;
          background: transparent;
        `
      default:
        return css`
          color: #b9b9b9;
          background-color: #ededed;
        `
    }
  }

  switch ($variant) {
    case 'gradient':
      return css`
        color: white;
        background: ${({ theme }) =>
          `linear-gradient(to right, ${theme.colors.secondary}, ${theme.colors.primary})`};

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          background-blend-mode: darken;
        }
      `
    case 'filled': {
      switch ($color) {
        case 'white':
          return css`
            color: ${({ theme }) => theme.colors.primary};
            background-color: white;

            &:hover {
              color: white;
              background-color: ${({ theme }) => theme.colors.primary};
            }
          `
        default:
          return css`
            color: white;
            background-color: ${({ theme }) => theme.colors[$color]};

            &:hover {
              background-color: ${({ theme }) =>
                colord(theme.colors[$color]).darken(0.1).toHex()};
            }
          `
      }
    }
    case 'outline':
      return css`
        color: ${({ theme }) => theme.colors[$color]};
        border: 1px solid ${({ theme }) => theme.colors[$color]};
        background-color: transparent;

        &:hover {
          background-color: ${({ theme }) =>
            colord(theme.colors[$color]).alpha(0.1).toHex()};
        }
      `
    default:
      return css``
  }
}

export const StyledButton = styled.button<StyledButtonProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  height: 36px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${applyVariant}
`

import { css } from 'styled-components'

export default [
  {
    name: 'clickable',
    value: css`
      cursor: pointer;
    `,
  },
]

export const CHOOSE_FILE = {
  en: 'Choose {n, plural, =1 {a file} other {files}}',
  th: 'เลือกไฟล์',
}

export const OR_DRAG_FILE = {
  en: ' or drag it here',
  th: ' หรือลากไฟล์มาวาง',
}

export const DROP_FILE_HERE = {
  en: 'Drop the {n, plural, =1 {file} other {files}} here',
  th: 'ลากไฟล์มาวางที่นี่',
}

import { ApiClient, ApiRequest } from '@/common/types'

class BrokerApis {
  public register: ApiRequest<RentAPerfect.Broker, { document: File }>
  public getProfile: ApiRequest<RentAPerfect.Broker>
  public updateProfile: ApiRequest<RentAPerfect.Broker, { document: File }>
  public getBrokerListings: ApiRequest<
    RentAPerfect.BrokerListing[],
    {
      keyword?: string
      roomStatus?: string
      approvalStatus?: string
      price?: [number, number]
    }
  >
  public requestListing: ApiRequest<
    RentAPerfect.BrokerListing,
    { roomId: string }
  >
  public deleteListing: ApiRequest<void, { brokerListingId: number }>
  public getBrokerListingRoom: ApiRequest<RentAPerfect.Room, { roomId: string }>

  constructor({ createRequest }: ApiClient) {
    this.register = createRequest({
      method: 'post',
      isFormData: true,
      url: () => '/broker',
      payload: ({ document }) => {
        const formData = new FormData()
        formData.append('files', document)
        return formData
      },
      getResponseData: response => response.data.brokerRequest,
    })

    this.getProfile = createRequest({
      method: 'get',
      url: () => '/broker',
    })

    this.updateProfile = createRequest({
      method: 'patch',
      isFormData: true,
      url: () => '/broker',
      payload: ({ document }) => {
        const formData = new FormData()
        formData.append('files', document)
        return formData
      },
    })

    this.getBrokerListings = createRequest({
      method: 'get',
      url: () => '/broker/listing',
      payload: ({ keyword, price, ...props }) => ({
        ...props,
        placeDetail: keyword,
        minPrice: price?.[0],
        maxPrice: price?.[1],
      }),
    })

    this.requestListing = createRequest({
      method: 'post',
      url: ({ roomId }) => `/broker/listing/${roomId}`,
    })

    this.deleteListing = createRequest({
      method: 'delete',
      url: ({ brokerListingId }) => `/broker/listing/${brokerListingId}`,
    })

    this.getBrokerListingRoom = createRequest({
      method: 'get',
      url: ({ roomId }) => `/broker/listing/${roomId}`,
      getResponseData: response => response.data.room,
    })
  }
}

export default BrokerApis

import Tooltip from 'rc-tooltip'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import React from 'react'
import styled from 'styled-components'

interface StyledTooltipProps {
  $width: number
}

const Component = ({
  className,
  ...rest
}: TooltipProps & { className?: string }) => (
  <Tooltip
    {...rest}
    overlayClassName={className}
    prefixCls='rc-tooltip-dropdown'
  />
)

export const StyledTooltip = styled(Component)<StyledTooltipProps>`
  width: ${({ $width }) => $width}px;
  padding-top: 4px;

  &.rc-tooltip-dropdown-hidden {
    display: none;
  }
`

export const Overflow = styled.div`
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

import styled from 'styled-components'

interface WrapperProps {
  $isOpen: boolean
}

export const Wrapper = styled.div.attrs({
  className: 'flex align-center justify-center',
})<WrapperProps>`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1060;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'auto' : 'none')};
`

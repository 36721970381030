import styled, { css } from 'styled-components'

const WIDTH = 20
const HEIGHT = 14

interface LineProps {
  $active: boolean
}

export const Wrapper = styled.div`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  position: relative;
  cursor: pointer;
`

export const Line = styled.div<LineProps>`
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  background-color: black;
  border-radius: 2px;
  transition: 0.25s ease-in-out;
  transform: rotate(0);
  opacity: 1;
`

export const Line1 = styled(Line)`
  top: 0;

  ${({ $active }) =>
    $active &&
    css`
      top: ${HEIGHT / 2}px;
      transform: rotate(135deg);
    `}
`

export const Line2 = styled(Line)`
  top: ${HEIGHT / 2}px;

  ${({ $active }) =>
    $active &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

export const Line3 = styled(Line)`
  top: ${HEIGHT}px;

  ${({ $active }) =>
    $active &&
    css`
      top: ${HEIGHT / 2}px;
      transform: rotate(-135deg);
    `}
`

import * as yup from 'yup'
import YupPassword from 'yup-password'

YupPassword(yup)

yup.setLocale({
  mixed: {
    default: { en: 'Invalid value', th: 'ข้อมูลไม่ถูกต้อง' },
    required: { en: 'This field is required', th: 'กรุณากรอกข้อมูล' },
  },
  string: {
    email: { en: 'Must be a valid email', th: 'กรุณากรอกอีเมล์' },
    min: ({ min }) => ({
      en: `Must be at least ${min} characters`,
      th: `ต้องมีความยาวอย่างน้อย ${min} ตัวอักษร`,
    }),
    max: ({ max }) => ({
      en: `Must be at most ${max} characters`,
      th: `ต้องมีความยาวอย่างน้อย ${max} ตัวอักษร`,
    }),
  },
  number: {
    positive: { en: 'Must be a positive number', th: 'ต้องเป็นตัวเลขจำนวนบวก' },
    negative: { en: 'Must be a negative number', th: 'ต้องเป็นตัวเลขจำนวนลบ' },
    min: ({ min }) => ({
      en: `Must be at least ${min}`,
      th: `ต้องมีค่ามากกว่า ${min}`,
    }),
    max: ({ max }) => ({
      en: `Must be at most ${max}`,
      th: `ต้องมีค่าน้อยกว่า ${max}`,
    }),
  },
  array: {
    min: ({ min }) => ({
      en: `Must have at least ${min} items`,
      th: `ต้องมีอย่างน้อย ${min} รายการ`,
    }),
    max: ({ max }) => ({
      en: `Must have at most ${max} items`,
      th: `ต้องมีอย่างมาก ${max} รายการ`,
    }),
  },
})

import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import React, { useRef, ReactNode } from 'react'

import Gap from '@/common/components/Gap'
import ArrowDownIcon from '@/common/components/icons/ArrowDownIcon'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import { isSSR } from '@/common/utils/server'

import { StyledTooltip, Overflow } from './styled'

const PLACEMENTS = {
  center: 'bottom',
  left: 'bottomLeft',
  right: 'bottomRight',
}

export interface DropDownProps extends Omit<TooltipProps, 'overlay'> {
  labelClassName?: string
  alignment?: 'center' | 'left' | 'right'
  component?: ReactNode
  label?: Translation
  width?: number
}

const DropDown = ({
  labelClassName,
  children,
  alignment = 'left',
  component,
  label,
  width = 162,
  ...props
}: DropDownProps) => {
  const ref = useRef(null)
  const { t } = useI18n()

  const modalElement = isSSR()
    ? null
    : (document?.getElementsByClassName('rc-dialog-body')?.[0] as HTMLElement)
  const isInModal = modalElement?.contains(ref.current)

  return (
    <StyledTooltip
      $width={width}
      overlay={<Overflow>{children}</Overflow>}
      placement={PLACEMENTS[alignment]}
      trigger='click'
      zIndex={isInModal ? 1070 : 1}
      getTooltipContainer={isInModal ? () => modalElement! : undefined}
      {...props}
    >
      <div ref={ref} className='flex width-fit-content clickable'>
        {component || (
          <Gap
            className='text-primary align-center width-fit-content'
            space={4}
          >
            {label ? (
              <div className={labelClassName ?? 'text-12 text-semibold'}>
                {t(label)}
              </div>
            ) : null}
            <ArrowDownIcon size={8} />
          </Gap>
        )}
      </div>
    </StyledTooltip>
  )
}

export default DropDown

import { css } from 'styled-components'

export default [
  {
    name: 'relative',
    value: css`
      position: relative;
    `,
  },
  {
    name: 'block',
    value: css`
      display: block;
    `,
  },
  {
    name: 'none',
    value: css`
      display: none;
    `,
  },
  {
    name: 'flex',
    value: css`
      display: flex;
    `,
  },
  {
    name: 'flex-1',
    value: css`
      flex: 1;
    `,
  },
  {
    name: 'flex-row',
    value: css`
      flex-direction: row;
    `,
  },
  {
    name: 'flex-row-reverse',
    value: css`
      flex-direction: row-reverse;
    `,
  },
  {
    name: 'flex-column',
    value: css`
      flex-direction: column;
    `,
  },
  {
    name: 'flex-column-reverse',
    value: css`
      flex-direction: column-reverse;
    `,
  },
  {
    name: 'align-center',
    value: css`
      align-items: center;
    `,
  },
  {
    name: 'align-start',
    value: css`
      align-items: flex-start;
    `,
  },
  {
    name: 'align-end',
    value: css`
      align-items: flex-end;
    `,
  },
  {
    name: 'align-stretch',
    value: css`
      align-items: stretch;
    `,
  },
  {
    name: 'justify-center',
    value: css`
      justify-content: center;
    `,
  },
  {
    name: 'justify-start',
    value: css`
      justify-content: flex-start;
    `,
  },
  {
    name: 'justify-end',
    value: css`
      justify-content: flex-end;
    `,
  },
  {
    name: 'justify-space-between',
    value: css`
      justify-content: space-between;
    `,
  },
  {
    name: 'justify-space-around',
    value: css`
      justify-content: space-around;
    `,
  },
  {
    name: 'flex-wrap',
    value: css`
      flex-wrap: wrap;
    `,
  },
  {
    name: 'flex-nowrap',
    value: css`
      flex-wrap: nowrap;
    `,
  },
]

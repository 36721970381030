import { css } from 'styled-components'

const AVAIABLE_SIZE = [
  'auto',
  0,
  2,
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  20,
  24,
  28,
  32,
  40,
  48,
  56,
  64,
  80,
  100,
]

export default [
  {
    name: 'full-screen-width',
    value: css`
      margin-left: calc(-50vw + 50%) !important;
      margin-right: calc(-50vw + 50%) !important;
      width: 100vw !important;
    `,
  },
  {
    name: 'full-width',
    value: css`
      width: 100% !important;
    `,
  },
  {
    name: 'full-height',
    value: css`
      height: 100% !important;
    `,
  },
  {
    name: 'width-fit-content',
    value: css`
      width: fit-content !important;
    `,
  },
  ...AVAIABLE_SIZE.map(size => [
    {
      name: `m-${size}`,
      value: css`
        margin: ${typeof size === 'number' ? `${size}px` : size} !important;
      `,
    },
    {
      name: `mx-${size}`,
      value: css`
        margin-left: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
        margin-right: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `my-${size}`,
      value: css`
        margin-top: ${typeof size === 'number' ? `${size}px` : size} !important;
        margin-bottom: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `ml-${size}`,
      value: css`
        margin-left: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `mr-${size}`,
      value: css`
        margin-right: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `mt-${size}`,
      value: css`
        margin-top: ${typeof size === 'number' ? `${size}px` : size} !important;
      `,
    },
    {
      name: `mb-${size}`,
      value: css`
        margin-bottom: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `p-${size}`,
      value: css`
        padding: ${typeof size === 'number' ? `${size}px` : size} !important;
      `,
    },
    {
      name: `px-${size}`,
      value: css`
        padding-left: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
        padding-right: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `py-${size}`,
      value: css`
        padding-top: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
        padding-bottom: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `pl-${size}`,
      value: css`
        padding-left: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `pr-${size}`,
      value: css`
        padding-right: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `pt-${size}`,
      value: css`
        padding-top: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `pb-${size}`,
      value: css`
        padding-bottom: ${typeof size === 'number'
          ? `${size}px`
          : size} !important;
      `,
    },
    {
      name: `gap-${size}`,
      value: css`
        gap: ${typeof size === 'number' ? `${size}px` : size} !important;
      `,
    },
  ]).flat(),
]

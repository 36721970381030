import React from 'react'

import { IconProps } from './types'

const UploadIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 32 32'
      height={size}
      width={size}
      {...props}
    >
      <g transform='translate(-912 -315)'>
        <path fill='none' d='M0 0H32V32H0z' transform='translate(912 315)' />
        <g transform='translate(912.882 264.06)'>
          <g transform='translate(0 55.032)'>
            <g>
              <path
                fill='currentColor'
                d='M25.086 62.864a10.349 10.349 0 0 0-20.359 1.768 5.645 5.645 0 0 0 .941 11.214h4.7v-1.881h-4.7a3.763 3.763 0 0 1 0-7.526.941.941 0 0 0 .941-.941 8.467 8.467 0 0 1 16.775-1.646.941.941 0 0 0 .8.753 4.7 4.7 0 0 1-.64 9.361H19.78v1.882h3.763a6.586 6.586 0 0 0 1.543-12.983z'
                transform='translate(0 -55.032)'
              />
              <path
                fill='currentColor'
                d='M184.8 249.285l-3.763 3.763 1.327 1.327 2.164-2.154v9.022h1.882v-9.023l2.154 2.154 1.327-1.327-3.763-3.763a.941.941 0 0 0-1.328.001z'
                transform='translate(-170.39 -237.605)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default UploadIcon

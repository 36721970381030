import styled from 'styled-components'

interface StyledSeparatorProps {
  $color: string
  $type: 'solid' | 'dashed'
}

export const StyledSeparator = styled.div<StyledSeparatorProps>`
  border-top: ${({ $color, $type }) => `1px ${$type} ${$color}`};
`

import { ApiClient, ApiRequest } from '@/common/types'

class CondoApis {
  public getPopularCondos: ApiRequest<RentAPerfect.PopularCondo[]>
  public getCondoDetail: ApiRequest<RentAPerfect.Condo, { placeId: string }>
  public getCondoRooms: ApiRequest<
    RentAPerfect.Room[],
    {
      placeId: string
      status?: string
      bedroom?: number
      size?: [number, number]
      floor?: string
      price?: [number, number]
      furniture?: string
    }
  >
  public uploadCondoImages: ApiRequest<
    RentAPerfect.CondoPhoto[],
    { placeId: string; files: File[] }
  >

  constructor({ createRequest }: ApiClient) {
    this.getPopularCondos = createRequest({
      method: 'get',
      url: () => '/condo/popular',
      getResponseData: response => response.data.condos,
    })

    this.getCondoDetail = createRequest({
      method: 'get',
      url: ({ placeId }) => `/condo/${placeId}/detail`,
      getResponseData: response => response.data.condo,
    })

    this.getCondoRooms = createRequest({
      method: 'get',
      url: ({ placeId }) => `/condo/${placeId}/rooms`,
      payload: ({ placeId, size, price, ...props }) => ({
        ...props,
        sizeMin: size?.[0],
        sizeMax: size?.[1],
        priceMin: price?.[0],
        priceMax: price?.[1],
      }),
      getResponseData: response => response.data.rooms,
    })

    this.uploadCondoImages = createRequest({
      method: 'post',
      isFormData: true,
      url: ({ placeId }) => `/condo/${placeId}/images`,
      payload: ({ files }) => {
        const formData = new FormData()
        files.forEach(file => formData.append('files', file))
        return formData
      },
    })
  }
}

export default CondoApis

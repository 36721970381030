import Tooltip from 'rc-tooltip'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import React from 'react'
import styled from 'styled-components'

const Component = ({
  className,
  ...rest
}: TooltipProps & { className?: string }) => (
  <Tooltip {...rest} overlayClassName={className} />
)

export const StyledTooltip = styled(Component)`
  width: 300px;
  padding-bottom: 0 !important;

  .rc-tooltip-arrow {
    bottom: -5px !important;
  }
`

import styled from 'styled-components'

export const InfoMessage = styled.div.attrs({
  className: 'text-12 text-medium mt-6',
})`
  color: #818a91;
`

export const ErrorMessage = styled(InfoMessage)`
  color: ${({ theme }) => theme.colors.error};
`

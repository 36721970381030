export enum Language {
  EN = 'en',
  TH = 'th',
}

export const defaultLang = Language.EN

export const supportedLangs = [
  { code: Language.EN, name: 'EN' },
  { code: Language.TH, name: 'ไทย' },
]

export const determineUserLang = (
  cookies,
  userLangs: readonly string[] = [],
  path = '/'
) => {
  const supportedLangCodes = supportedLangs.map(({ code }) => code)
  const urlLang = path.trim().split('/')[1] as Language
  const userLangCodes = userLangs.map(
    userLang => userLang.trim().replace('_', '-').split('-')[0] as Language
  )

  const code: Language = supportedLangCodes.includes(urlLang)
    ? urlLang
    : cookies.language ??
      userLangCodes.find(code => supportedLangCodes.includes(code)) ??
      defaultLang

  return {
    ...(supportedLangs.find(supportedLang => supportedLang.code === code) as {
      name: string
      code: Language
    }),
    basename: code !== urlLang && code === defaultLang ? '/' : `/${code}`,
    shouldRedirect: ![defaultLang, urlLang].includes(code),
  }
}

import { css } from 'styled-components'

import { isSSR } from '@/common/utils/server'

export const media = {
  down:
    (size: string) =>
    (...args) =>
      css`
        @media (max-width: ${({ theme }) => theme.breakpoints[size] - 1}px) {
          ${css(...(args as Parameters<typeof css>))}
        }
      `,
  up:
    (size: string) =>
    (...args) =>
      css`
        @media (min-width: ${({ theme }) => theme.breakpoints[size]}px) {
          ${css(...(args as Parameters<typeof css>))}
        }
      `,
}

export const isHoverableDevice = isSSR()
  ? false
  : window.matchMedia('(hover: hover)').matches

import React, { HTMLAttributes } from 'react'

import { Wrapper, Line1, Line2, Line3 } from './styled'

interface HamburgerProps extends HTMLAttributes<HTMLDivElement> {
  active: boolean
}

const Hamburger = ({ active, ...props }: HamburgerProps) => {
  return (
    <Wrapper {...props}>
      <Line1 $active={active} />
      <Line2 $active={active} />
      <Line3 $active={active} />
    </Wrapper>
  )
}

export default Hamburger

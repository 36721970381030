import loadable from '@loadable/component'
import React, { ReactNode, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import Footer from '@/common/components/Footer'
import Header from '@/common/components/Header'
import {
  PRIVACY_POLICY_TITLE,
  PRIVACY_POLICY_CONTENT,
  TERMS_AND_CONDITIONS_TITLE,
  TERMS_AND_CONDITIONS_CONTENT,
} from '@/common/constants'
import useGlobalState from '@/common/hooks/useGlobalState'
import { prefetchUserAgent } from '@/common/hooks/useUserAgent'
import { PrefetchRequest } from '@/common/types'
import { prefetchCurrentUser } from '@/modules/authentication/hooks/useCurrentUser'

import { Wrapper, Body } from './styled'

const DynamicContentModal = loadable(
  () => import('@/common/components/DynamicContentModal'),
  { ssr: false }
)
const RegisterSuggestionModal = loadable(
  () => import('@/modules/authentication/components/RegisterSuggestionModal'),
  { ssr: false }
)

interface MainPageLayoutProps {
  children: ReactNode
}

const MainPageLayout = ({ children }: MainPageLayoutProps) => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { openingModal, closeModal } = useGlobalState()

  const ref = searchParams.get('ref')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!ref) return
    sessionStorage.setItem('ref', ref)
  }, [ref])

  return (
    <>
      <Wrapper>
        <Header />
        <Body>{children}</Body>
        <Footer />
      </Wrapper>
      <DynamicContentModal
        title={PRIVACY_POLICY_TITLE}
        content={PRIVACY_POLICY_CONTENT}
        isOpen={openingModal === 'privacy_policy'}
        onClose={closeModal}
        mobileFullScreen
      />
      <DynamicContentModal
        title={TERMS_AND_CONDITIONS_TITLE}
        content={TERMS_AND_CONDITIONS_CONTENT}
        isOpen={openingModal === 'terms_and_conditions'}
        onClose={closeModal}
        mobileFullScreen
        indentParagraph
      />
      <RegisterSuggestionModal
        isOpen={openingModal === 'register_suggestion'}
        onClose={closeModal}
      />
    </>
  )
}

MainPageLayout.prefetch = async (request: PrefetchRequest) => {
  await Promise.all([prefetchUserAgent(request), prefetchCurrentUser(request)])
}

export default MainPageLayout

import React, { HTMLAttributes } from 'react'

import { StyledGap } from './styled'

export interface GapProps extends HTMLAttributes<HTMLDivElement> {
  space: number
  vertical?: boolean
  tabletSpace?: number
  tabletVertical?: boolean
  mobileSpace?: number
  mobileVertical?: boolean
}

const Gap = ({
  space,
  vertical = false,
  tabletSpace = space,
  tabletVertical = vertical,
  mobileSpace = tabletSpace,
  mobileVertical = tabletVertical,
  ...props
}: GapProps) => (
  <StyledGap
    {...props}
    $space={space}
    $vertical={vertical}
    $tabletSpace={tabletSpace}
    $tabletVertical={tabletVertical}
    $mobileSpace={mobileSpace}
    $mobileVertical={mobileVertical}
  />
)

export default Gap
